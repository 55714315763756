import { Card, Box, Flex, Text, rem } from '@mantine/core'
import { IconBook2, IconChevronRight } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import LevelTitle from 'src/components/learning/LevelTitle/LevelTitle'

import styles from './LevelSwitcherMenuItemCard.module.css'

type Props = {
  title: string
  isActive: boolean
}

const LevelSwitcherMenuItemCard = ({ title, isActive }: Props) => {
  const { t } = useTranslation('learning')
  return (
    <Card
      bg="gray.1"
      radius={rem(10)}
      className={styles.menuItemCard}
      p="xs"
      style={{ opacity: isActive ? 0.6 : 1 }}
    >
      <Flex align="center">
        <Flex justify="center" h="100%">
          <IconBook2 size={24} />
        </Flex>
        <Box px={rem(8)}>
          <LevelTitle title={title} />
          <Text size={rem(12)} pt={rem(4)}>
            {isActive ? t('common.active') : t('main.access-level')}
          </Text>
        </Box>
        <Box ml="auto">
          <IconChevronRight size={24} />
        </Box>
      </Flex>
    </Card>
  )
}

export default LevelSwitcherMenuItemCard
