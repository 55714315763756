import { useState } from 'react'

import {
  Flex,
  Group,
  Title,
  Paper,
  Image,
  Button,
  LoadingOverlay,
  Box,
  rem,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import store from 'store2'
import { Module } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { GET_SIMPLIFIED_MODULE_BY_COURSE } from 'src/graphql/learning/learning'
import { getStoreLevel } from 'src/helpers/storage'

const UnitsCompletion = () => {
  const { t } = useTranslation('learning')
  const [units, setUnits] = useState({ currentUnit: null, nextUnit: null })

  const storeLevel = getStoreLevel()
  const unit = JSON.parse(store('unit'))

  const { data, loading } = useQuery(GET_SIMPLIFIED_MODULE_BY_COURSE, {
    variables: { id: storeLevel?.id },
    onCompleted: () => {
      const {
        FindCourse: { modules },
      } = data

      const { id } = unit
      const currentUnit = modules.find((module: Module) => module.id === id)
      const nextUnit = modules.find((module: Module) => module.id === id + 1)
      setUnits({ currentUnit, nextUnit })
    },
  })

  const goToNextUnit = () => {
    const { id, title } = units?.nextUnit || {}
    store('unit', JSON.stringify({ id, title }))
    navigate(routes.learningUnitLessons())
  }

  const goToHome = () => {
    navigate(routes.learningHomePage())
  }

  return (
    <>
      {loading ? (
        <LoadingOverlay visible overlayProps={{ backgroundOpacity: 1 }} />
      ) : (
        <Flex
          w="100%"
          h="100%"
          p="md"
          direction="column"
          align="center"
          data-sel="congratulations-unit"
        >
          <Paper
            radius="100%"
            w={rem(230)}
            h={rem(230)}
            bg="blue.0"
            p={rem(35)}
            mx="auto"
          >
            <Flex w="100%" h="100%" data-sel="media">
              <Image src="/learning/unit-congratulations.png" fit="contain" />
            </Flex>
          </Paper>
          <Group gap={rem(8)} py="lg">
            <Title order={3} c="cnaRed.6" data-sel="title-highlight">
              {t('common.unit')} {units?.currentUnit?.title}
            </Title>
            <Title order={3} data-sel="title">
              {t('main.completed')}!
            </Title>
          </Group>
          <Title order={5} ta="center" data-sel="description">
            {t('main.activity-congratulations')}
          </Title>
          <Box py="lg" w="100%" maw={rem(350)}>
            <Button
              h={rem(50)}
              w="100%"
              onClick={goToNextUnit}
              data-sel="go-to-next"
            >
              {t('main.go-to')} {units?.nextUnit?.title}
            </Button>
            <Button
              bg="white"
              c="black"
              h={rem(50)}
              w="100%"
              onClick={goToHome}
              style={{
                marginTop: 'var(--mantine-spacing-lg)',
                borderColor: 'var(--mantine-color-gray-3)',
              }}
              data-sel="go-to-back"
            >
              {t('main.back-to-home')}
            </Button>
          </Box>
        </Flex>
      )}
    </>
  )
}

export default UnitsCompletion
