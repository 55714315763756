import store from 'store2'

function toLocaleSupportsLocales() {
  return (
    typeof Intl === 'object' &&
    !!Intl &&
    typeof Intl.DateTimeFormat === 'function'
  )
}

export const formatDate = (date: string): string => {
  if (!toLocaleSupportsLocales()) {
    return ''
  }

  const currentLanguage = store('currentLocale') || 'pt_BR'
  const locale = currentLanguage === 'pt_BR' ? 'pt-BR' : 'en'

  const newDate = new Date(date)

  return newDate?.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
}

export const formatTime = (date: string): string => {
  if (!toLocaleSupportsLocales()) {
    return ''
  }

  const currentLanguage = store('currentLocale') || 'pt_BR'
  const locale = currentLanguage === 'pt_BR' ? 'pt-BR' : 'en'

  const newDate = new Date(date)

  return newDate?.toLocaleTimeString(locale, { timeStyle: 'short' })
}
