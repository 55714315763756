import { Anchor, Box, Stack, Text, rem } from '@mantine/core'

import { Link } from '@redwoodjs/router'

import { EXTERNAL_URL_REGEX } from 'src/helpers/url'

type Props = {
  label: string
  icon: React.ReactNode
  to: string
}

export default function Shortcut({ label, icon, to }: Props) {
  const isExternalUrl = EXTERNAL_URL_REGEX.test(to)

  return (
    <Anchor
      component={isExternalUrl ? 'a' : Link}
      to={isExternalUrl ? undefined : to}
      href={isExternalUrl ? to : undefined}
      target={isExternalUrl ? '_blank' : undefined}
      underline="never"
      display="inline-flex"
      data-sel="shortcut"
    >
      <Stack gap={rem(8)} align="center" w={rem(72)}>
        <Box
          style={{ borderRadius: rem(8) }}
          w={rem(48)}
          h={rem(48)}
          p={rem(12)}
          bg="gray.1"
        >
          {icon}
        </Box>
        <Text c="black" fw="500" size="xs" lh="1.55" ta="center">
          {label}
        </Text>
      </Stack>
    </Anchor>
  )
}
