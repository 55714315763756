const useImagePreload = (urls: string[] = []) => {
  if (!urls?.length) return
  for (const url of urls) {
    const img = new Image()
    img.src = url
  }
}

const useImageIsCached = (src: string) => {
  if (!src) return
  const image = new Image()
  image.src = src
  return image.complete
}

export { useImagePreload, useImageIsCached }
