import { Grid, Paper, Flex, Box, Title, Text, Anchor, rem } from '@mantine/core'
import { IconClipboardText } from '@tabler/icons-react'
import store from 'store2'
import { Module } from 'types/graphql'

import { routes, navigate } from '@redwoodjs/router'

type LessonsProps = {
  lessons: Module[]
  unitId: number
}

const Lessons = ({ lessons, unitId }: LessonsProps) => {
  const goToLesson = (lesson: Module) => {
    const { id, title } = lesson

    store('lesson', JSON.stringify({ id, title }))

    navigate(
      routes.learningActivityPage({
        unitId: unitId,
        lessonId: id,
      })
    )
  }
  return (
    <Grid>
      {lessons.map((lesson) => {
        return (
          <Grid.Col
            data-sel="unit-lesson"
            span={{ base: 6, sm: 3, lg: 2 }}
            key={lesson.id}
          >
            <Anchor
              underline="never"
              c="black"
              onClick={() => goToLesson(lesson)}
            >
              <Paper bg="white" withBorder radius={rem(10)} p="md">
                <Flex direction="column" h="100%">
                  <IconClipboardText
                    size={24}
                    color="var(--mantine-color-cnaRed-6)"
                  />
                  <Box mt="auto" pt="md">
                    <Title lineClamp={2} order={5} pt="xs">
                      {lesson.title}
                    </Title>
                    {lesson.description && (
                      <Text lineClamp={2} mt="xs" size={rem(14)}>
                        {lesson.description}
                      </Text>
                    )}
                  </Box>
                </Flex>
              </Paper>
            </Anchor>
          </Grid.Col>
        )
      })}
    </Grid>
  )
}

export default Lessons
