const LIST_ACTIVITIES = gql`
  query ListActivities {
    FilterActivities {
      id
      instruction_en
      typology
    }
  }
`

const GET_ACTIVITY = gql`
  query GetActivity($id: Int!) {
    FindActivity(id: $id) {
      id
      typology
      contextMedia
      contextText
      instruction_en
      instruction_pt_br
      status
      feedbacks {
        type
        title_en
        title_pt_br
        explanation_en
        explanation_pt_br
      }
      modules {
        moduleId
        order
      }
      answerFields {
        text
        id
        coverMedia
        order
        choices {
          id
          text
          isCorrect
          coverMedia
          order
        }
      }
    }
  }
`

const GET_ACTIVITY_TO_ADD_TO_MODULE = gql`
  query GetActivityToAddToModule($id: Int!) {
    FindActivity(id: $id) {
      id
      instruction_en
      typology
    }
  }
`

const GET_MODULES = gql`
  query GetModulesList($courseId: Int!) {
    FilterModules(courseId: $courseId) {
      id
      slug
      title
      description
      coverMedia
      course {
        id
        title
      }
      subModules {
        id
        moduleId
        order
        subModule {
          id
          title
        }
      }
    }
  }
`

const GET_MODULE = gql`
  query GetModuleForEdit($id: Int!) {
    FindModule(id: $id) {
      id
      slug
      title
      description
      coverMedia
      activities {
        id
        order
        activity {
          id
          instruction_en
          typology
        }
      }
    }
  }
`

const GET_SIMPLIFIED_SUBMODULES = gql`
  query Submodules($parentModuleId: Int, $status: StatusEnum) {
    FilterModules(parentModuleId: $parentModuleId, status: $status) {
      id
      courseId
      subModules {
        order
        moduleId
        subModule {
          id
          title
        }
      }
    }
  }
`

const CREATE_ACTIVITY = gql`
  mutation CreateActivity($input: CreateActivityWithNestedInput!) {
    CreateActivity(input: $input) {
      id
    }
  }
`

const UPDATE_ACTIVITY = gql`
  mutation UpdateActivity($id: Int!, $input: UpdateActivityWithNestedInput!) {
    UpdateActivity(id: $id, input: $input) {
      id
    }
  }
`

const DELETE_ACTIVITY = gql`
  mutation DeleteActivity($id: Int!) {
    DeleteActivity(id: $id) {
      id
    }
  }
`

const ADD_ACTIVITY_TO_MODULE = gql`
  mutation AddActivityToModule($input: CreateActivityOnModuleInput!) {
    AttachActivityOnModule(input: $input) {
      id
    }
  }
`

const REMOVE_ACTIVITY_FROM_MODULE = gql`
  mutation RemoveActivityFromModule($activityId: Int!, $moduleId: Int) {
    DettachActivityFromModule(activityId: $activityId, moduleId: $moduleId) {
      id
    }
  }
`

const SWAP_ACTIVITY_ORDER = gql`
  mutation SwapActivityOrderOnModule(
    $input: [SwapActivityOrderOnModuleInput]!
  ) {
    SwapActivityOrderOnModule(input: $input) {
      id
      order
    }
  }
`

const CREATE_MODULE = gql`
  mutation CreateModule($input: CreateModuleInput!) {
    CreateModule(input: $input) {
      id
    }
  }
`

const ATTACH_SUBMODULE_TO_MODULE = gql`
  mutation AttachSubModuleToModule($input: CreateSubModuleOnModuleInput!) {
    AttachSubModule(input: $input) {
      id
    }
  }
`

const GET_RESOURCES = gql`
  query GetResources {
    FilterResources {
      id
      name
      media
    }
  }
`

const DELETE_RESOURCE = gql`
  mutation DeleteResource($id: Int!) {
    DeleteResource(id: $id) {
      id
    }
  }
`

const GET_RESOURCE = gql`
  query GetResource($id: Int!) {
    FindResource(id: $id) {
      id
      name
      media
      order
    }
  }
`

const CREATE_RESOURCE = gql`
  mutation CreateResource($input: CreateResourceInput!) {
    CreateResource(input: $input) {
      id
    }
  }
`

const UPDATE_RESOURCE = gql`
  mutation UpdateResource($id: Int!, $input: UpdateResourceInput!) {
    UpdateResource(id: $id, input: $input) {
      id
    }
  }
`

const GET_RESOURCES_BY_MODULE = gql`
  query GetResourcesByModule($moduleId: Int) {
    FilterResources(moduleId: $moduleId) {
      id
      name
      media
      order
    }
  }
`

const ATTACH_RESOURCE_TO_MODULE = gql`
  mutation AttachResourceToModule(
    $id: Int!
    $input: AttachResourceToModuleInput!
  ) {
    AttachResourceToModule(id: $id, input: $input) {
      id
    }
  }
`

const DETACH_RESOURCE_FROM_MODULE = gql`
  mutation DetachResourceFromModule($id: Int!) {
    DetachResourceFromModule(id: $id) {
      id
    }
  }
`

const SWAP_RESOURCE_ORDER = gql`
  mutation SwapResourceOrder($input: [SwapResourceOrderInput]!) {
    SwapResourceOrder(input: $input) {
      id
      order
    }
  }
`

export {
  LIST_ACTIVITIES,
  GET_ACTIVITY,
  GET_MODULES,
  GET_MODULE,
  GET_ACTIVITY_TO_ADD_TO_MODULE,
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
  DELETE_ACTIVITY,
  ADD_ACTIVITY_TO_MODULE,
  REMOVE_ACTIVITY_FROM_MODULE,
  GET_SIMPLIFIED_SUBMODULES,
  SWAP_ACTIVITY_ORDER,
  CREATE_MODULE,
  ATTACH_SUBMODULE_TO_MODULE,
  GET_RESOURCES,
  DELETE_RESOURCE,
  GET_RESOURCE,
  CREATE_RESOURCE,
  UPDATE_RESOURCE,
  GET_RESOURCES_BY_MODULE,
  DETACH_RESOURCE_FROM_MODULE,
  ATTACH_RESOURCE_TO_MODULE,
  SWAP_RESOURCE_ORDER,
}
