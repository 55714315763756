import { em } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

const useIsVerySmallScreen = () => {
  return useMediaQuery(`(max-width: ${em(374)})`)
}

const useIsSmallScreen = () => {
  return useMediaQuery(`(max-width: ${em(991)})`)
}

export { useIsSmallScreen, useIsVerySmallScreen }
