import { useEffect, useState } from 'react'

import { Text, Loader, Stack, Title, Anchor } from '@mantine/core'

import { navigate, routes } from '@redwoodjs/router'
import { MetaTags } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { LEGACY_CNANET_URL } from 'src/helpers/env'

const HomePage = () => {
  const [isLegacy, setIsLegacy] = useState(false)
  const { currentUser, isAuthenticated } = useAuth()

  // Redirect the user after 3 seconds to legacy platform if applicable
  useEffect(() => {
    if (isAuthenticated && currentUser) {
      const { shouldRedirect } = currentUser

      if (shouldRedirect) {
        setIsLegacy(true)
        setTimeout(() => {
          window.location.href = LEGACY_CNANET_URL
        }, 3000)
      } else {
        navigate(routes.learningHomePage())
      }
    }
  }, [currentUser, isAuthenticated])

  return (
    <>
      <MetaTags title="Home" description="Home page" />

      {isLegacy && (
        <Stack align="center">
          <Loader />

          <Title order={3}>Redirecionando...</Title>

          <Text>Estamos te redirecionando para o CNA Net.</Text>

          <Text size="sm" variant="subtle">
            <Anchor href={LEGACY_CNANET_URL}>Clique aqui</Anchor> se você não
            for redirecionado...
          </Text>
        </Stack>
      )}
    </>
  )
}

export default HomePage
