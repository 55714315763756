import { Text, Title, rem } from '@mantine/core'
import { useTranslation } from 'react-i18next'

const LevelSwitcherHeader = () => {
  const { t } = useTranslation('learning')

  return (
    <>
      <Title pb="xs" order={5}>
        {t('main.change-level')}
      </Title>
      <Text size={rem(14)} pb={rem(6)}>
        {t('main.select-level')}
      </Text>
    </>
  )
}

export default LevelSwitcherHeader
