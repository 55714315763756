import { useState } from 'react'

import { Box, Text, Divider, Flex, Accordion, Group, rem } from '@mantine/core'
import { IconMusic, IconVideo } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import { useIsSmallScreen } from 'src/hooks/shared/screen'
import { UnitResource } from 'src/pages/Learning/ResourcesPage/ResourcesPage'

import Resource from '../Resource'

type Props = {
  resources: UnitResource[]
  tab: string
}

const Resources = ({ resources, tab }: Props) => {
  const { t } = useTranslation('learning')
  const [currentMediaId, setCurrentMediaId] = useState<number | null>(null)

  const isSmallScreen = useIsSmallScreen()

  const onMediaPlay = (id: number) => {
    if (currentMediaId === id) {
      return
    }
    setCurrentMediaId(id)
  }

  if (!resources.length) {
    return (
      <Box w="100%">
        <Text py={rem(16)}>{t('main.no-resources-found')}</Text>
      </Box>
    )
  }

  return (
    <Box w="100%" maw={rem(800)}>
      {isSmallScreen ? (
        <Accordion
          key={tab}
          mt={rem(24)}
          styles={{
            item: {
              border: 'none',
              backgroundColor: 'var(--mantine-color-gray-0)',
              borderRadius: rem(8),
            },
            content: {
              paddingBottom: 0,
            },
          }}
          data-sel="resources-accordion"
        >
          {resources.map((unit, unitIndex) => (
            <Accordion.Item
              mb={rem(16)}
              py={rem(8)}
              key={unitIndex}
              value={`unit-${unitIndex}`}
              data-sel="resource-accordion-item"
            >
              <Accordion.Control>
                <Group>
                  {tab === 'audio' ? (
                    <IconMusic size={20} />
                  ) : (
                    <IconVideo size={20} />
                  )}
                  <Text data-sel="unit-title" size={rem(14)} c="black" fw="500">
                    {unit.title}
                  </Text>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                {unit.resources.map((resource, resourceIndex) => (
                  <Resource
                    key={`${unitIndex}-${resourceIndex}`}
                    resource={resource}
                    tab={tab}
                    onMediaPlay={onMediaPlay}
                    currentMediaId={currentMediaId}
                  />
                ))}
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      ) : (
        resources.map((unit, unitIndex) => (
          <Box key={unitIndex} data-sel="resource-item">
            <Flex align="center" pt={rem(16)} pb={rem(28)}>
              <Divider w="100%" />
              <Text
                style={{ flexGrow: 1, flexShrink: 0 }}
                size={rem(14)}
                px={rem(8)}
                data-sel="unit-title"
              >
                {unit.title}
              </Text>
              <Divider w="100%" />
            </Flex>
            {unit.resources.map((resource, resourceIndex) => (
              <Resource
                key={`${unitIndex}-${resourceIndex}`}
                resource={resource}
                tab={tab}
                onMediaPlay={onMediaPlay}
                currentMediaId={currentMediaId}
              />
            ))}
          </Box>
        ))
      )}
    </Box>
  )
}

export default Resources
