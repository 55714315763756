import { useContext } from 'react'

import {
  Flex,
  Box,
  Divider,
  UnstyledButton,
  Badge,
  Title,
  Text,
  rem,
} from '@mantine/core'
import { IconRotateClockwise } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import Markdown from 'src/components/shared/Markdown/Markdown'
import { useIsSmallScreen, useIsVerySmallScreen } from 'src/hooks/shared/screen'
import { ActivityResolverContext } from 'src/providers/learning/activity/ActivityResolverProvider'

import FeedbackTranslate from '../FeedbackTranslate/FeedbackTranslate'

type NegativeFeedbackProps = {
  onToggle: () => void
  opened: boolean
  title: string
  explanation: string
}

const NegativeFeedbackBody = ({
  title,
  explanation,
}: NegativeFeedbackProps) => {
  const { answerFeedback, setCorrectAnswerVisible } = useContext(
    ActivityResolverContext
  )
  const { attempts } = answerFeedback
  const { t } = useTranslation('learning')

  const isSmallScreen = useIsSmallScreen()
  const isVerySmallScreen = useIsVerySmallScreen()

  const isDesktop = !isVerySmallScreen && !isSmallScreen

  const onShowCorrectAnswer = () => setCorrectAnswerVisible()

  return (
    <Box pt={isDesktop ? 0 : 'lg'} data-sel="negative-feedback-body">
      <Flex justify="flex-end" direction="column" h="100%">
        <Flex direction="column" align="center">
          <IconRotateClockwise
            size={36}
            color="var(--mantine-color-cnaRed-7)"
          />
          <Badge
            size={rem(16)}
            fw="500"
            c="cnaRed.7"
            bg="cnaRed.0"
            py="lg"
            px="sm"
            mt="md"
            data-sel="negative-feedback-badge"
          >
            {t('main.lets-try-again')}
          </Badge>
        </Flex>
        <Divider my="lg" />
        <Flex justify="space-between" pb="sm">
          <Title order={5} m={0} data-sel="feedback-title">
            {title}
          </Title>
          <FeedbackTranslate />
        </Flex>
        <Markdown data-sel="feedback-explanation" content={explanation} />
        {attempts > 1 && (
          <UnstyledButton
            mt="sm"
            onClick={onShowCorrectAnswer}
            data-sel="show-answer"
          >
            <Text c="cnaRed.6" td="underline">
              {t('main.show-answer')}
            </Text>
          </UnstyledButton>
        )}
      </Flex>
    </Box>
  )
}

export default NegativeFeedbackBody
