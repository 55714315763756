import React from 'react'

import { Grid, Title, Card, rem } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import store from 'store2'
import { LevelUnit } from 'types/levelUnit'

import { routes } from '@redwoodjs/router'
import { navigate } from '@redwoodjs/router'

import { Unit } from 'src/components/learning/Unit/Unit'
import { ModuleCategoryEnum } from 'src/enums/learning/module/ModuleCategoryEnum'
import { useLevel } from 'src/providers/learning/level/LevelProvider'

import NoUnitsFound from '../NoUnitsFound/NoUnitsFound'

const Units = () => {
  const { levelUnits } = useLevel()
  const { t } = useTranslation('learning')

  const goToUnit = (unit: LevelUnit) => {
    const { id, title } = unit
    store('unit', JSON.stringify({ id, title }))
    navigate(routes.learningUnitLessons())
  }

  const commonUnits = levelUnits?.filter(
    (unit) => unit.category !== ModuleCategoryEnum.EXAMINATION
  )

  const examUnits = levelUnits?.filter(
    (unit) => unit.category === ModuleCategoryEnum.EXAMINATION
  )

  return (
    <>
      {!levelUnits?.length && (
        <Card withBorder radius="md" mt="lg">
          <NoUnitsFound />
        </Card>
      )}
      <Grid
        gutter="lg"
        pt={{ base: 'xl', md: 0 }}
        pb={rem(5)}
        data-sel="units-list"
      >
        {commonUnits?.map((unit, index) => {
          return (
            <Grid.Col key={index} span={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Unit unit={unit} onClick={goToUnit} />
            </Grid.Col>
          )
        })}
        {examUnits?.length > 0 && (
          <>
            <Grid.Col span={12}>
              <Title order={4}>{t('main.tests')}</Title>
            </Grid.Col>
            {examUnits?.map((unit, index) => (
              <Grid.Col
                key={index}
                span={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }}
              >
                <Unit unit={unit} onClick={goToUnit} />
              </Grid.Col>
            ))}
          </>
        )}
      </Grid>
    </>
  )
}

export default Units
