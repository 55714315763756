import React, { useState, useEffect } from 'react'

import { RingProgress, Text, rem } from '@mantine/core'
type CircleProgressBarProps = {
  progress?: number
  size?: number
  thickness?: number
}
const CircleProgressBar = ({
  progress = 0,
  size = 54,
  thickness = 4,
}: CircleProgressBarProps) => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    if (progress === 100 || progress === 0) {
      setValue(progress)
    } else {
      const interval = setInterval(() => {
        if (value >= progress) {
          clearInterval(interval)
          return
        }
        setValue((value) => value + 1)
      }, 5)

      return () => clearInterval(interval)
    }
  }, [value, progress])

  return (
    <RingProgress
      size={size}
      thickness={thickness}
      data-sel="circle-progress"
      sections={[
        {
          value: value,
          color: 'cnaRed.6',
        },
      ]}
      label={
        <Text c="black" fw={500} fz={rem(10)} ta="center">
          {value}%
        </Text>
      }
    />
  )
}

export default CircleProgressBar
