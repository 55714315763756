import { AppShell } from '@mantine/core'

import { useIsSmallScreen } from 'src/hooks/shared/screen'
import { ActivityProvider } from 'src/providers/learning/activity/ActivityProvider'
import { ActivityResolverProvider } from 'src/providers/learning/activity/ActivityResolverProvider'
import { LevelProvider } from 'src/providers/learning/level/LevelProvider'

type props = {
  children?: React.ReactNode
}

const LearningWritingActivityLayout = ({ children }: props) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <LevelProvider>
      <ActivityProvider>
        <ActivityResolverProvider>
          <AppShell bg={isSmallScreen ? 'white' : 'color-body'}>
            <AppShell.Main>{children}</AppShell.Main>
          </AppShell>
        </ActivityResolverProvider>
      </ActivityProvider>
    </LevelProvider>
  )
}

export default LearningWritingActivityLayout
