import { Title } from '@mantine/core'

type Props = {
  title: string
}

const LevelTitle = ({ title }: Props) => {
  return (
    <Title data-sel="current-level-title" order={5}>
      {title}
    </Title>
  )
}

export default LevelTitle
