import { TitleOrder, useMantineTheme } from '@mantine/core'
import { Highlight, Title, rem } from '@mantine/core'

type HighlightTitleProps = {
  title: string
  size?: TitleOrder
  color?: string
}

const HighlightTitle = ({ title, size = 4 }: HighlightTitleProps) => {
  const theme = useMantineTheme()

  const {
    headings: { sizes, fontWeight },
  } = theme

  const { fontSize } = sizes[`h${size}`]

  return (
    <Title order={size}>
      <Highlight
        highlight={title.split(' ').slice(1)}
        highlightStyles={{
          WebkitBackgroundClip: 'text',
          color: theme.colors.cnaRed[6],
        }}
        size={rem(fontSize)}
        styles={{
          root: {
            fontWeight: fontWeight,
          },
        }}
      >
        {title}
      </Highlight>
    </Title>
  )
}

export default HighlightTitle
