import { Group } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { items } from 'src/components/learning/NavBar/NavBar'
import Shortcut from 'src/components/shared/Shortcut'

export default function ShortcutsNav() {
  const { t } = useTranslation('learning')

  return (
    <Group mt="xl" align="flex-start" data-sel="shortcuts-nav">
      {items?.map((item, index) => (
        <Shortcut
          key={index}
          label={t(item.label)}
          icon={item.icon}
          to={item.route}
        />
      ))}
    </Group>
  )
}
