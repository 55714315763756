import { Group, Anchor, ScrollArea, Text, Flex, rem } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { MenuItem } from 'types/menuItem'

import { Link, useLocation } from '@redwoodjs/router'

import { EXTERNAL_URL_REGEX } from 'src/helpers/url'

type FooterMenuProps = {
  menu: MenuItem[]
}

const FooterMenu = ({ menu }: FooterMenuProps) => {
  const { t } = useTranslation('learning')

  const location = useLocation()

  return (
    <ScrollArea type="never">
      <Group
        w="100%"
        h={rem(100)}
        px="md"
        py="lg"
        wrap="nowrap"
        justify="space-between"
        align="center"
        data-sel="footer-menu"
      >
        {menu.map((item, index) => {
          const isExternalUrl = EXTERNAL_URL_REGEX.test(item.route)

          return (
            <Anchor
              size="xs"
              key={index}
              component={isExternalUrl ? 'a' : Link}
              to={isExternalUrl ? undefined : item.route}
              href={isExternalUrl ? item.route : undefined}
              target={isExternalUrl ? '_blank' : undefined}
              underline="never"
              c={location.pathname === item.route ? 'cnaRed.6' : 'gray.6'}
              styles={{
                root: {
                  transition: 'color 0.2s ease',
                },
              }}
            >
              <Flex
                w={rem(72)}
                direction="column"
                justify="center"
                align="center"
              >
                {item.icon}
                <Text span ta="center">
                  {t(item.label)}
                </Text>
              </Flex>
            </Anchor>
          )
        })}
      </Group>
    </ScrollArea>
  )
}

export default FooterMenu
