import { Flex, Group, Box, Text, UnstyledButton, rem } from '@mantine/core'
import { IconChevronLeft } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import { back } from '@redwoodjs/router'
import { navigate } from '@redwoodjs/router'

import { useIsSmallScreen } from 'src/hooks/shared/screen'

import LanguageSelectI18n from '../../shared/LanguageSelectI18n/LanguageSelectI18n'
import HighlightTitle from '../HighlightTitle/HighlightTitle'

type TopMainSectionProps = {
  title?: string
  navigation?: boolean | string
}

const TopMainSection = ({ title, navigation }: TopMainSectionProps) => {
  const { t } = useTranslation('learning')
  const isSmallScreen = useIsSmallScreen()

  const onNavigate = () => {
    if (navigation === true) {
      back()
    } else if (typeof navigation === 'string') {
      navigate(navigation)
    }
  }

  return (
    <Flex
      mb={rem(35)}
      className="main-top-section"
      direction={isSmallScreen && !navigation ? 'column-reverse' : 'row'}
      justify={isSmallScreen ? 'flex-end' : 'space-between'}
    >
      {navigation ? (
        <Flex align="center">
          {navigation && (
            <UnstyledButton
              onClick={onNavigate}
              mr={rem(10)}
              data-sel="back-navigation"
            >
              <Group gap={rem(5)}>
                <IconChevronLeft size={24} />
                <Text>{t('common.back')}</Text>
              </Group>
            </UnstyledButton>
          )}
          {title && (
            <Box mr="auto">
              <HighlightTitle title={title} />
            </Box>
          )}
        </Flex>
      ) : (
        <>
          <Box mt={isSmallScreen ? rem(40) : 0}>
            <HighlightTitle title={title} />
          </Box>
        </>
      )}
      <Box ml="auto">
        <LanguageSelectI18n />
      </Box>
    </Flex>
  )
}

export default TopMainSection
