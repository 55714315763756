import { Container, rem, em } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
type ContainerWrapProps = {
  children?: React.ReactNode
  fluid?: boolean
}
const ContainerWrap = ({ children, fluid = false }: ContainerWrapProps) => {
  const isSmallScreen = useMediaQuery(`(max-width: ${em(991)})`)
  return (
    <Container
      pt={isSmallScreen ? rem(20) : rem(55)}
      maw={fluid ? '100%' : rem(1440)}
      px={fluid ? 0 : 'md'}
    >
      {children}
    </Container>
  )
}

export default ContainerWrap
