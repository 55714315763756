import { useEffect, useCallback, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  Grid,
  Box,
  Title,
  Group,
  Paper,
  Text,
  Badge,
  Flex,
  Button,
  Overlay,
  rem,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import store from 'store2'
import { Navigation, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Module, Maybe } from 'types/graphql'

import { routes } from '@redwoodjs/router'
import { useParams } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import Lessons from 'src/components/learning/Lessons/Lessons'
import Levels from 'src/components/learning/Levels/Levels'
import Media from 'src/components/learning/Media'
import NavBar from 'src/components/learning/NavBar/NavBar'
import NoUnitsFound from 'src/components/learning/NoUnitsFound/NoUnitsFound'
import TopMainSection from 'src/components/learning/TopMainSection/TopMainSection'
import CircleProgressBar from 'src/components/shared/CircleProgressBar/CircleProgressBar'
import { notifications } from 'src/components/shared/Notifications'
import { GET_SUBMODULES } from 'src/graphql/learning/learning'
import { orderedSubModules } from 'src/helpers/module'
import { calculateProgressPercentage } from 'src/helpers/progress'
import { useFirstMedia } from 'src/hooks/shared/media'
import { useIsSmallScreen } from 'src/hooks/shared/screen'
import { useLevel } from 'src/providers/learning/level/LevelProvider'

import 'swiper/css'

import styles from './UnitLessonsPage.module.css'

type ModuleLessonsData = {
  FilterModules?: Maybe<Module[]>
}

const UnitMedia = ({ coverMedia }) => {
  const { url, type } = useFirstMedia(coverMedia)
  return <Media type={type} url={url} radius={rem(10)} />
}

const UnitLessonsPage = () => {
  const [
    getModuleLessons,
    { loading: loadingLessons, data: lessons, error: lessonsError },
  ] = useLazyQuery<ModuleLessonsData>(GET_SUBMODULES)

  const [swiperInstance, setSwiperInstance] = useState(null)

  const { t } = useTranslation('learning')
  const { levelUnits, courseProgress } = useLevel()
  const isSmallScreen = useIsSmallScreen()
  const { slug } = useParams()

  const getLessons = useCallback(
    async (parentModuleId: number) => {
      let currentModuleId = parentModuleId

      const moduleOnLevel = levelUnits.find(
        (unit) => unit.id === parentModuleId
      )

      if (!moduleOnLevel) {
        currentModuleId = levelUnits[0]?.id
      }

      if (!currentModuleId) return

      await getModuleLessons({
        variables: {
          parentModuleId: currentModuleId,
        },
      })
    },
    [levelUnits, getModuleLessons]
  )

  const setCurrentUnit = (index: number) => {
    const currentUnit = levelUnits[index]
    const { id, title } = currentUnit || {}
    store('unit', JSON.stringify({ id, title }))
    getLessons(currentUnit.id)
  }

  useEffect(() => {
    const currentUnit = JSON.parse(store.get('unit'))
    const { id } = currentUnit || {}

    if (swiperInstance) {
      const unitIndex = levelUnits?.indexOf(
        levelUnits.find((module) => module.id === parseInt(id, 10))
      )

      getLessons(id)

      if (unitIndex === 0) return

      swiperInstance.slideTo(unitIndex, 0)
    }
  }, [levelUnits, slug, getLessons, swiperInstance])

  useEffect(() => {
    if (lessonsError) {
      notifications.error({
        title: t('main.loading-error'),
        message: '',
      })
    }
  }, [lessonsError, t])

  const hasModules = levelUnits && levelUnits.length > 0
  const lessonsModules = orderedSubModules(lessons?.FilterModules)

  const unitId =
    hasModules && swiperInstance && levelUnits[swiperInstance.activeIndex]?.id

  return (
    <Levels>
      <Metadata title="Lessons" description="Lessons page" />
      <Grid gutter="lg" data-sel="units-lessons">
        {!isSmallScreen && (
          <Grid.Col span={{ md: 4, lg: 3 }} m={0}>
            <NavBar />
          </Grid.Col>
        )}
        <Grid.Col span={{ xs: 12, md: 8, lg: 9 }} data-sel="units-list">
          {!hasModules && <NoUnitsFound />}
          {hasModules && (
            <>
              <TopMainSection navigation={routes.learningHomePage()} />
              <Paper
                withBorder
                radius={rem(8)}
                bg="white"
                p="md"
                mt="lg"
                className={styles.units}
                shadow="sm"
              >
                {loadingLessons && (
                  <Overlay
                    style={{ styles }}
                    opacity={0.8}
                    bg="white"
                    radius={rem(8)}
                    data-sel="unit-lessons-overlay"
                  />
                )}
                <Swiper
                  modules={[Navigation, A11y]}
                  slidesPerView={1}
                  navigation={{
                    nextEl: '.learning-next-unit',
                    prevEl: '.learning-prev-unit',
                  }}
                  spaceBetween={10}
                  onSwiper={(swiper) => {
                    setSwiperInstance(swiper)
                  }}
                  onSlideChange={(swiper) => setCurrentUnit(swiper.activeIndex)}
                >
                  {levelUnits?.map((unit, index) => {
                    const unitProgress = courseProgress.find(
                      (cp) => cp.id === unit.id
                    )

                    const progress = calculateProgressPercentage(unitProgress)

                    const totalActivities = unitProgress?.total
                    const totalActivitiesCompleted = unitProgress?.completed

                    return (
                      <SwiperSlide key={index} data-sel="unit-swiper">
                        <Grid data-sel="unit-card">
                          <Grid.Col
                            span={{ xs: 12, md: 3 }}
                            className={styles.mediaCol}
                            mt="auto"
                          >
                            <UnitMedia coverMedia={unit.coverMedia} />
                          </Grid.Col>
                          <Grid.Col span={{ xs: 12, md: 9 }}>
                            <Flex direction="column" h="100%">
                              <Group gap={rem(5)} ml={rem(-6)}>
                                <Box>
                                  <CircleProgressBar
                                    size={60}
                                    thickness={5}
                                    progress={progress}
                                  />
                                </Box>
                                <Title data-sel="unit-title" order={4}>
                                  {unit.title}
                                </Title>
                              </Group>
                              {totalActivities &&
                              totalActivitiesCompleted >= 0 ? (
                                <Group
                                  pt="sm"
                                  gap={rem(10)}
                                  className={styles.badgeProgress}
                                >
                                  <Badge
                                    c="green.9"
                                    bg="green.1"
                                    h={rem(34)}
                                    size="sm"
                                  >
                                    {t('main.completed-of-total')
                                      .replace(
                                        '{completed}',
                                        `${totalActivitiesCompleted}`
                                      )
                                      .replace('{total}', `${totalActivities}`)}
                                  </Badge>
                                </Group>
                              ) : (
                                <></>
                              )}

                              <Text py="md" size="sm">
                                {unit.description}
                              </Text>
                            </Flex>
                          </Grid.Col>
                        </Grid>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
                <Box className={styles.unitsNavigation}>
                  <Group
                    w="100%"
                    justify={isSmallScreen ? 'space-between' : 'flex-end'}
                  >
                    <Button
                      data-sel="prev-unit"
                      className="learning-prev-unit"
                      h={rem(50)}
                    >
                      Prev. unit
                    </Button>
                    <Button
                      data-sel="next-unit"
                      className="learning-next-unit"
                      h={rem(50)}
                    >
                      Next unit
                    </Button>
                  </Group>
                </Box>
              </Paper>
              <Title order={4} py="md">
                {t('main.lessons')}
              </Title>
              {!loadingLessons && lessonsModules?.length === 0 && (
                <Text size="sm">{t('main.no-lessons')}</Text>
              )}
              {lessonsModules?.length > 0 && (
                <Lessons lessons={lessonsModules} unitId={unitId} />
              )}
            </>
          )}
        </Grid.Col>
      </Grid>
    </Levels>
  )
}

export default UnitLessonsPage
