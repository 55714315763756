import { Fragment } from 'react'

import { Box, Paper, Flex, Text, rem } from '@mantine/core'
import { ActivityAnswerField } from 'types/graphql'

import Media from 'src/components/learning/Media'
import Markdown from 'src/components/shared/Markdown/Markdown'
import { useFirstMedia } from 'src/hooks/shared/media'

type Props = {
  answer: ActivityAnswerField
  count?: number
  showChoicesCount?: boolean
}

const Answer = ({ answer, count, showChoicesCount }: Props) => {
  const firstMedia = useFirstMedia

  return (
    <Flex pt={rem(8)}>
      {count && (
        <Text size="sm" pr={rem(4)}>
          {`(${count})`}
        </Text>
      )}
      <Flex w="100%" wrap="wrap" gap="xs">
        {answer?.choices?.map((choice, index) => {
          const { type, url } = firstMedia(choice?.coverMedia)
          const hasCoverMedia = url && type

          const text = choice.text
          return (
            <Fragment key={choice.id}>
              {hasCoverMedia ? (
                <Box w="calc(50% - 8px)" data-sel="answer-item">
                  {showChoicesCount && (
                    <Text size="sm">{`(${index + 1})`}</Text>
                  )}
                  <Paper radius={rem(12)}>
                    <Flex direction="column" align="center" w="100%" h="100%">
                      <Media py={0} title={text} type={type} url={url} zoom />
                      {text && <Markdown content={text} />}
                    </Flex>
                  </Paper>
                </Box>
              ) : (
                <Text w="100%" size="sm" data-sel="answer-item">
                  <Markdown
                    content={showChoicesCount ? `(${index + 1}) ${text}` : text}
                  />
                </Text>
              )}
            </Fragment>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default Answer
