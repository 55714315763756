import { useState } from 'react'

import {
  Card,
  Skeleton,
  Box,
  Flex,
  Title,
  Text,
  Group,
  UnstyledButton,
  rem,
} from '@mantine/core'
import { LevelUnit } from 'types/levelUnit'

import Media from 'src/components/learning/Media'
import CircleProgressBar from 'src/components/shared/CircleProgressBar/CircleProgressBar'
import { calculateProgressPercentage } from 'src/helpers/progress'
import { useFirstMedia } from 'src/hooks/shared/media'
import { useLevel } from 'src/providers/learning/level/LevelProvider'

import styles from './Unit.module.css'

type UnitItemType = {
  image: string
  progress: number
  title: string
  description: string
  id: string
  active: boolean
}

type UnitProps = {
  unit: LevelUnit
  onClick?: (unit: LevelUnit) => void
}

const UnitCard = ({ unit }: UnitProps) => {
  const { courseProgress } = useLevel()
  const unitProgress = courseProgress.find((cp) => cp.id === unit.id)
  const progress = calculateProgressPercentage(unitProgress)

  return (
    <Flex align="center" p="md" h={rem(96)}>
      <CircleProgressBar progress={progress} />
      <Box pl="md" w="100%">
        <Group justify="space-between">
          <Title c="dark.2" order={5} data-sel="unit-title">
            {unit.title}
          </Title>
        </Group>
        <Group>
          <Text c="dark.2" size="sm" lineClamp={2}>
            {unit.description}
          </Text>
        </Group>
      </Box>
    </Flex>
  )
}

const UnitCardLoading = () => (
  <Box p="md" data-sel="unit-card-loading">
    <Flex justify="center">
      <Skeleton height={rem(120)} mb={rem(10)} />
    </Flex>
    <Flex gap={0} align="center">
      <Box w={rem(50)}>
        <Skeleton height={rem(56)} circle />
      </Box>
      <Box w="100%" pl={rem(20)}>
        <Skeleton height={rem(8)} mt={rem(6)} w="100%" radius="xl" />
        <Skeleton height={rem(8)} mt={rem(6)} radius="xl" />
      </Box>
    </Flex>
  </Box>
)

const Unit = ({ unit, onClick }: UnitProps) => {
  const [loading, setLoading] = useState(true)
  const { url, type } = useFirstMedia(unit.coverMedia)
  return (
    <Card
      p={0}
      m={rem(3)}
      data-sel="unit-card"
      className={styles.unitCard}
      styles={{
        root: {
          borderRadius: 'var(--mantine-radius-md)',
          border: '1px solid var(--mantine-color-gray-3)',
        },
      }}
    >
      <UnstyledButton onClick={() => onClick(unit)} data-sel="unit-action">
        <Box>
          <Media
            type={type}
            url={url}
            py={0}
            onLoad={() => setLoading(false)}
          />
        </Box>
        {!loading && <UnitCard unit={unit} />}
        {loading && <UnitCardLoading />}
      </UnstyledButton>
    </Card>
  )
}

export { Unit, UnitItemType }
