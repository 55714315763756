import { useEffect, useState } from 'react'

import {
  Container,
  Modal,
  Group,
  Text,
  Card,
  Anchor,
  ThemeIcon,
  TextInput,
  Title,
  rem,
  LoadingOverlay,
  Table,
  Tooltip,
  Button,
  Loader,
} from '@mantine/core'
import { useDebouncedValue, useDisclosure } from '@mantine/hooks'
import {
  IconArrowLeft,
  IconCirclePlus,
  IconEdit,
  IconSearch,
  IconTrash,
  IconVideo,
  IconHeadphones,
} from '@tabler/icons-react'

import { Link, routes, useParams } from '@redwoodjs/router'
import { useQuery, useMutation } from '@redwoodjs/web'

import { notifications } from 'src/components/shared/Notifications'
import { ContentMediaType } from 'src/enums/learning/media/ContentMediaType'
import {
  GET_RESOURCES,
  DELETE_RESOURCE,
} from 'src/graphql/learning/learning-admin'

import EditResource from './components/EditResource/EditResource'

const ResourcesListPage = () => {
  const { id } = useParams()

  const [opened, { open, close }] = useDisclosure(false)
  const [
    bottomSheetOpened,
    { open: openBottomSheet, close: closeBottomSheet },
  ] = useDisclosure(false)

  const [resourceId, setResourceId] = useState(null)
  const [query, setQuery] = useState('')
  const [resources, setResources] = useState([])

  const [debouncedQuery] = useDebouncedValue(query, 500)

  const [deleteResource, { loading: deleteResourceLoading }] =
    useMutation(DELETE_RESOURCE)

  const { data, loading, error, refetch } = useQuery(GET_RESOURCES)

  const onConfirmDelete = (resourceId) => {
    setResourceId(resourceId)
    open()
  }

  const onDeleteResource = async () => {
    if (deleteResourceLoading) return

    try {
      await deleteResource({
        variables: {
          id: resourceId,
        },
      })

      notifications.success({
        title: 'Resource deletado com sucesso',
        message: 'O resource foi deletado com sucesso.',
      })
      close()
      refetch()
    } catch (_) {
      notifications.error({
        title: 'Erro ao deletar o resource',
        message: 'Recarregue a página e tente novamente.',
      })
    }
  }

  if (error) {
    notifications.error({
      title: 'Erro ao listar resources',
      message: 'Recarregue a página para tentar novamente.',
    })
  }

  useEffect(() => {
    if (loading) return

    if (debouncedQuery === '') {
      return setResources(data?.FilterResources)
    }

    if (!debouncedQuery) return

    const FilterResources = data?.FilterResources.filter((resource) =>
      resource?.name?.toLowerCase().includes(debouncedQuery.toLowerCase())
    )
    setResources(FilterResources)
  }, [debouncedQuery, data, loading])

  useEffect(() => {
    if (id) {
      setResourceId(parseInt(id, 10))
      openBottomSheet()
    }
  }, [id, openBottomSheet])

  return (
    <Container maw={rem(1140)}>
      <Anchor
        component={Link}
        underline="never"
        to={routes.learningAdminHomePage()}
      >
        <Group>
          <ThemeIcon radius="xl" size="md" variant="light">
            <IconArrowLeft size={16} />
          </ThemeIcon>

          <Text>Início</Text>
        </Group>
      </Anchor>
      <Group my="xl" justify="space-between" align="flex-end">
        <Group>
          <Title order={2}>Resources</Title>
          <Anchor
            underline="never"
            component={Link}
            to="#"
            onClick={() => {
              setResourceId(null)
              openBottomSheet()
            }}
          >
            <Group gap={rem(4)}>
              <Text>Novo resource</Text>
              <IconCirclePlus />
            </Group>
          </Anchor>
        </Group>

        <TextInput
          leftSection={<IconSearch size={18} />}
          placeholder="Pesquisar resources"
          onChange={(event) => setQuery(event.currentTarget.value)}
          miw={rem(350)}
        />
      </Group>
      <Card withBorder radius="lg">
        <LoadingOverlay visible={loading} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th w={10}>#</Table.Th>
              <Table.Th>Resource</Table.Th>
              <Table.Th w={100}>Tipo</Table.Th>
              <Table.Th w={100}></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {resources.map((resource) => (
              <Table.Tr key={resource.id}>
                <Table.Td>{resource.id}</Table.Td>
                <Table.Td>
                  <Text>{resource.name}</Text>
                </Table.Td>
                <Table.Td>
                  {resource.media &&
                    resource.media[0]?.type?.toUpperCase() ===
                      ContentMediaType.AUDIO && <IconHeadphones size={24} />}
                  {resource.media &&
                    resource.media[0]?.type?.toUpperCase() ===
                      ContentMediaType.VIDEO && <IconVideo size={24} />}
                </Table.Td>
                <Table.Td>
                  <Group justify="flex-end">
                    <Tooltip inline label="Editar">
                      <Anchor
                        component={Link}
                        display="flex"
                        to="#"
                        onClick={() => {
                          setResourceId(resource.id)
                          openBottomSheet()
                        }}
                      >
                        <IconEdit />
                      </Anchor>
                    </Tooltip>
                    <Tooltip inline label="Excluir">
                      <Anchor
                        onClick={() => {
                          onConfirmDelete(resource.id)
                        }}
                        display="flex"
                      >
                        <IconTrash />
                      </Anchor>
                    </Tooltip>
                  </Group>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Card>
      <Modal opened={opened} onClose={close}>
        <Title order={3}>Deletar resource #{resourceId}</Title>
        <Title pt={rem(20)} order={5}>
          Tem certeza que deseja deletar esse resource? Essa operação não pode
          ser desfeita.
        </Title>
        <Group pt={rem(16)} justify="flex-end">
          <Button w={rem(180)} bg="cnaBlue.5" onClick={close}>
            Cancelar
          </Button>
          <Button w={rem(100)} onClick={onDeleteResource}>
            {deleteResourceLoading ? (
              <Loader size={rem(24)} color="white" c="white" />
            ) : (
              'Deletar'
            )}
          </Button>
        </Group>
      </Modal>
      <EditResource
        opened={bottomSheetOpened}
        onClose={closeBottomSheet}
        onRefetch={refetch}
        id={resourceId}
      />
    </Container>
  )
}

export default ResourcesListPage
