import { Text, Title, TitleOrder, rem } from '@mantine/core'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const Markdown = ({
  content,
  paragraph = true,
  ...props
}: {
  content: string
  paragraph?: boolean
}) => {
  const headings = {}

  for (let order = 1; order <= 6; order++) {
    headings[`h${order}`] = ({ ...props }) => {
      return <Title order={order as TitleOrder}>{props.children}</Title>
    }
  }

  return (
    <span style={{ pointerEvents: 'none' }} {...props}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          strong: ({ ...props }) => (
            <Text span c="cnaRed.6" fw={500}>
              {props.children}
            </Text>
          ),
          em: ({ node, ...props }) => {
            const { children } = props

            const startChar = content[node.position.start.offset]
            const endChar = content[node.position.end.offset - 1]

            return startChar === '_' && endChar === '_' ? (
              <Text span fs="italic" m={0} p={0}>
                {children}
              </Text>
            ) : (
              <Text span fw={600} m={0} p={0}>
                {children}
              </Text>
            )
          },
          p: ({ ...props }) => {
            return (
              <Text
                py={paragraph ? 0 : rem(8)}
                span={paragraph}
                data-sel="markdown-text"
              >
                {props.children}
              </Text>
            )
          },
          a: ({ ...props }) => {
            return <Text span>{props.children}</Text>
          },
          ...headings,
        }}
      >
        {content}
      </ReactMarkdown>
    </span>
  )
}

export default Markdown
