import { Paper, Title, Text, Flex, Anchor, Image, rem } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Link, routes } from '@redwoodjs/router'

import { useIsSmallScreen } from 'src/hooks/shared/screen'

const WritingListPlaceholder = () => {
  const { t } = useTranslation('learning')
  const isSmallScreen = useIsSmallScreen()
  return (
    <Paper withBorder p="lg" radius="md" bg="white" data-sel="placeholder">
      <Flex direction="column" align="center" justify="center">
        <Image
          w="100%"
          h="100%"
          maw={rem(isSmallScreen ? 200 : 300)}
          mah={rem(isSmallScreen ? 200 : 300)}
          src="/on-hold.png"
          data-sel="placeholder-image"
        />
        <Title data-sel="placeholder-title" order={5}>
          {t('main.no-pending-activities')}
        </Title>
        <Text
          data-sel="placeholder-description"
          my="md"
          size={rem(12)}
          ta="center"
          styles={{ root: { lineHeight: rem(16) } }}
        >
          {t('main.no-pending-activities-description')}
        </Text>
        <Anchor
          component={Link}
          to={routes.learningHomePage()}
          underline="never"
          mt={rem(5)}
          data-sel="go-to-activities"
        >
          <Paper withBorder p="md" radius="xl" bg="white">
            <Text c="black" fw={500} size={rem(16)}>
              {t('main.go-to-activities')}
            </Text>
          </Paper>
        </Anchor>
      </Flex>
    </Paper>
  )
}

export default WritingListPlaceholder
