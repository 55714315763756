import { Module } from 'types/graphql'

const orderedSubModules = (modules: Module[] | Module) => {
  if (!Array.isArray(modules)) {
    modules = [modules]
  }

  if (!modules || modules.length === 0) return []

  let subModuleOnModule = []

  modules.forEach((module) => {
    if (!module || !module.subModules) return

    const sortedSubModules = [...module.subModules].sort(
      (a, b) => a.order - b.order
    )

    const flattenedFromCurrentModule = sortedSubModules.map((submodule) => {
      return {
        order: submodule.order,
        id: submodule.subModule.id,
        title: submodule.subModule.title,
        description: submodule.subModule.description,
        coverMedia: submodule.subModule.coverMedia,
      }
    })

    const subModules = flattenedFromCurrentModule.filter(
      (subModule) => Object.keys(subModule).length > 0
    )

    subModuleOnModule = [...subModuleOnModule, ...subModules]
  })

  return subModuleOnModule
}

export { orderedSubModules }
