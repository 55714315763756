import Answer from 'src/components/learning/CorrectAnswer/components/Answer'
import { CorrectAnswerChildrenAnswerProps } from 'src/components/learning/CorrectAnswer/CorrectAnswer'

const CorrectMultipleChoice = ({
  answers,
}: CorrectAnswerChildrenAnswerProps) => {
  return (
    <>
      {answers.map((answer, index) => (
        <Answer
          key={answer.id}
          answer={answer}
          count={answers.length > 1 ? index + 1 : undefined}
        />
      ))}
    </>
  )
}

export default CorrectMultipleChoice
