import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useCallback,
} from 'react'

import { FeedbackEnum } from 'src/enums/learning/activity/FeedbackEnum'
type ActivityResolverProviderProps = {
  children: ReactNode
}

type ActivityFeedback = {
  type: FeedbackEnum
  explanation: string
  title: string
  isFeedbackVisible: boolean
  isCorrectAnswerVisible: boolean
  attempts?: number
  language: string
}

interface ActivityResolverState {
  answer: unknown[]
  answerFeedback: ActivityFeedback
  setAnswer: (answer: unknown[]) => void
  setAnswerFeedback: (answerFeedback: object) => void
  resetAnswer: () => void
  resetAnswerFeedback: () => void
  hideAnswerFeedback: () => void
  setCorrectAnswerVisible: () => void
  setFeedbackLanguage: () => void
}

const ActivityResolverContext = createContext<ActivityResolverState>(undefined)
const emptyAnswerFeedback = {
  type: null,
  explanation: '',
  title: '',
  isFeedbackVisible: false,
  isCorrectAnswerVisible: false,
  attempts: 0,
  language: 'en',
}
const useActivityResolver = (): ActivityResolverState => {
  const context = useContext(ActivityResolverContext)

  if (!context) {
    throw new Error(
      'useActivityResolver must be used within an ActivityResolverProvider'
    )
  }

  return {
    answer: context.answer,
    answerFeedback: context.answerFeedback,
    setAnswer: context.setAnswer,
    setAnswerFeedback: context.setAnswerFeedback,
    resetAnswer: context.resetAnswer,
    resetAnswerFeedback: context.resetAnswerFeedback,
    hideAnswerFeedback: context.hideAnswerFeedback,
    setCorrectAnswerVisible: context.setCorrectAnswerVisible,
    setFeedbackLanguage: context.setFeedbackLanguage,
  }
}

const ActivityResolverProvider = ({
  children,
}: ActivityResolverProviderProps) => {
  const [answer, setAnswer] = useState<unknown[]>([])

  const [answerFeedback, setAnswerFeedback] =
    useState<ActivityFeedback>(emptyAnswerFeedback)

  const resetAnswer = useCallback(() => setAnswer([]), [])

  const resetAnswerFeedback = useCallback(
    () => setAnswerFeedback(emptyAnswerFeedback),
    []
  )

  const hideAnswerFeedback = () => {
    setAnswerFeedback((prev) => ({
      ...prev,
      isFeedbackVisible: false,
    }))
  }

  const setCorrectAnswerVisible = () => {
    setAnswerFeedback((prev) => ({
      ...prev,
      isFeedbackVisible: false,
      isCorrectAnswerVisible: true,
    }))
  }

  const setFeedbackLanguage = () => {
    setAnswerFeedback((prev) => ({
      ...prev,
      language: prev.language === 'en' ? 'pt_br' : 'en',
    }))
  }

  const value = {
    answer,
    answerFeedback,
    setAnswer,
    setAnswerFeedback,
    resetAnswer,
    resetAnswerFeedback,
    hideAnswerFeedback,
    setCorrectAnswerVisible,
    setFeedbackLanguage,
  }

  return (
    <ActivityResolverContext.Provider value={value}>
      {children}
    </ActivityResolverContext.Provider>
  )
}
export {
  ActivityResolverProvider,
  useActivityResolver,
  ActivityResolverContext,
}
