import { useEffect } from 'react'

import { HOTJAR_ID } from 'src/helpers/env'

const Hotjar = () => {
  useEffect(() => {
    const script = document.createElement('script')

    if (HOTJAR_ID) {
      script.innerHTML = `
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `
      document.head.appendChild(script)
    }

    return () => {
      if (HOTJAR_ID && script) {
        document.head.removeChild(script)
      }
    }
  }, [])

  return null
}

export default Hotjar
