import { Menu, UnstyledButton, rem } from '@mantine/core'

import { LevelSwitcherChildrenProps } from '../../LevelSwitcher'
import LevelSwitcherHeader from '../LevelSwitcherHeader/LevelSwitcherHeader'
import LevelSwitcherMenuItemCard from '../LevelSwitcherMenuItemCard/LevelSwitcherMenuItemCard'

const LevelSwitcherDropdown = ({
  children,
  onClick,
  levels,
  currentLevel,
}: LevelSwitcherChildrenProps) => {
  return (
    <Menu
      width="target"
      styles={{
        dropdown: {
          borderRadius: rem(10),
          marginTop: rem(20),
        },
        label: {
          color: 'black',
        },
        item: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Menu.Target>
        <UnstyledButton data-sel="level-switcher" w="100%">
          {children}
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>
          <LevelSwitcherHeader />
        </Menu.Label>
        {levels.map((level, index) => {
          const isActive = currentLevel.slug === level.slug
          return (
            <Menu.Item
              key={index}
              onClick={() => onClick(level)}
              data-sel="level-item"
            >
              <LevelSwitcherMenuItemCard
                isActive={isActive}
                title={level.title}
              />
            </Menu.Item>
          )
        })}
      </Menu.Dropdown>
    </Menu>
  )
}

export default LevelSwitcherDropdown
