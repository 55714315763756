import React, { useContext, useEffect } from 'react'

import { Box, Collapse } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import BottomSheet from 'src/components/shared/BottomSheet/BottomSheet'
import { FeedbackEnum } from 'src/enums/learning/activity/FeedbackEnum'
import { useNextLesson } from 'src/hooks/learning/activity'
import { useIsSmallScreen } from 'src/hooks/shared/screen'
import { ActivityContext } from 'src/providers/learning/activity/ActivityProvider'
import { ActivityResolverContext } from 'src/providers/learning/activity/ActivityResolverProvider'

import styles from './ActivityFeedback.module.css'
import FeedbackContent from './components/FeedbackContent/FeedbackContent'
import NegativeFeedbackBody from './components/NegativeFeedbackBody/NegativeFeedbackBody'
import NegativeFeedbackFooter from './components/NegativeFeedbackFooter/NegativeFeedbackFooter'
import NeutralFeedbackBody from './components/NeutralFeedbackBody/NeutralFeedbackBody'
import NeutralFeedbackFooter from './components/NeutralFeedbackFooter/NeutralFeedbackFooter'
import PositiveFeedbackBody from './components/PositiveFeedbackBody/PositiveFeedbackBody'
import PositiveFeedbackFooter from './components/PositiveFeedbackFooter/PositiveFeedbackFooter'

const ActivityFeedback = () => {
  const [opened, { toggle }] = useDisclosure(false)
  const { activity, activities, setActivity } = useContext(ActivityContext)

  const { answerFeedback, hideAnswerFeedback } = useContext(
    ActivityResolverContext
  )

  const { isFeedbackVisible, type, language } = answerFeedback

  const title = answerFeedback[`title_${language}`]
  const explanation = answerFeedback[`explanation_${language}`]

  const isSmallScreen = useIsSmallScreen()

  const feedbackComponentMap: { [key in FeedbackEnum]: React.ElementType[] } = {
    [FeedbackEnum.NEGATIVE]: [NegativeFeedbackBody, NegativeFeedbackFooter],
    [FeedbackEnum.POSITIVE]: [PositiveFeedbackBody, PositiveFeedbackFooter],
    [FeedbackEnum.NEUTRAL]: [NeutralFeedbackBody, NeutralFeedbackFooter],
  }

  const onClose = () => {
    hideAnswerFeedback()
  }

  const { goToNextLesson, loading } = useNextLesson(onClose)

  const onNextActivity = () => {
    const currentActivityIndex = activities.findIndex(
      (ac) => ac.id === activity.id
    )

    const nextActivity = activities[currentActivityIndex + 1]

    if (nextActivity) {
      onClose()
      setActivity(nextActivity)
    } else {
      goToNextLesson()
    }
  }

  const onTryAgain = () => onClose()
  const onSkip = () => onNextActivity()
  const onShowAnswer = () => {}

  useEffect(() => {
    return () => {
      if (opened) toggle()
    }
  })

  const [FeedbackBody, FeedbackFooter] = feedbackComponentMap[type] || []

  const FeedbackBodyContent = () => (
    <FeedbackContent
      title={title}
      explanation={explanation}
      onToggle={toggle}
      onShowAnswer={onShowAnswer}
    >
      {FeedbackBody}
    </FeedbackContent>
  )

  const FeedbackFooterContent = () => (
    <FeedbackContent
      onNextActivity={onNextActivity}
      onTryAgain={onTryAgain}
      onSkip={onSkip}
      loading={loading}
    >
      {FeedbackFooter}
    </FeedbackContent>
  )

  return isSmallScreen ? (
    <BottomSheet
      opened={isFeedbackVisible}
      onClose={onClose}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <BottomSheet.Body>
        <FeedbackBodyContent />
      </BottomSheet.Body>
      <BottomSheet.Footer>
        <FeedbackFooterContent />
      </BottomSheet.Footer>
    </BottomSheet>
  ) : (
    <Collapse in={isFeedbackVisible} transitionDuration={300}>
      <Box className={styles.activityFeedback}>
        <FeedbackBodyContent />
      </Box>
      <Box pt="md">
        <FeedbackFooterContent />
      </Box>
    </Collapse>
  )
}

export default ActivityFeedback
