import { createTheme, MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'

import { RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import i18nextInit from 'src/i18next'
import Hotjar from 'src/lib/hotjar'
import Sentry from 'src/lib/sentry'
import FatalErrorPage from 'src/pages/FatalErrorPage/FatalErrorPage'
import Routes from 'src/Routes'

import './scaffold.css'
import { AuthProvider, useAuth } from './auth'
import './index.css'
import { resolver, theme } from './mantine.config'

import '@mantine/core/styles/global.css'
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import '@mantine/dropzone/styles.css'

const mantineTheme = createTheme(theme)

i18nextInit()

const App = () => (
  <Sentry.ErrorBoundary fallback={FatalErrorPage}>
    <MantineProvider theme={mantineTheme} cssVariablesResolver={resolver}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <RedwoodApolloProvider
            useAuth={useAuth}
            graphQLClientConfig={{
              httpLinkConfig: { credentials: 'include' },
            }}
          >
            <Notifications position="top-center" />
            <Routes />
            <Hotjar />
          </RedwoodApolloProvider>
        </AuthProvider>
      </RedwoodProvider>
    </MantineProvider>
  </Sentry.ErrorBoundary>
)

export default App
