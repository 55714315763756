import React, { useState, useEffect, useRef } from 'react'

import {
  Drawer,
  UnstyledButton,
  Flex,
  ScrollArea,
  Box,
  Title,
  Group,
  rem,
} from '@mantine/core'
import { IconChevronDown } from '@tabler/icons-react'

type BottomSheetProps = {
  opened: boolean
  onClose: () => void
  children?: React.ReactNode
  withCloseButton?: boolean
  title?: string
  closeOnClickOutside?: boolean
  closeOnEscape?: boolean
}

const BottomSheet = ({
  opened,
  onClose,
  children,
  withCloseButton = false,
  title,
  closeOnClickOutside = true,
  closeOnEscape = true,
}: BottomSheetProps) => {
  const [contentHeight, setContentHeight] = useState(0)
  const drawerContentRef = useRef(null)

  useEffect(() => {
    const updateHeight = () => {
      if (drawerContentRef.current) {
        const drawerContent = drawerContentRef.current.querySelector(
          'section[role="dialog"]'
        )
        if (drawerContent) {
          setContentHeight(drawerContent.offsetHeight)
        }
      }
    }

    updateHeight()

    window.addEventListener('resize', updateHeight)

    if (opened && drawerContentRef.current) {
      const observer = new MutationObserver(updateHeight)
      const config = { childList: true, subtree: true, attributes: true }
      observer.observe(drawerContentRef.current, config)

      return () => {
        observer.disconnect()
        window.removeEventListener('resize', updateHeight)
      }
    }
  }, [opened])

  const childrenArray = React.Children.toArray(children)

  const body = childrenArray.filter(
    (child) => React.isValidElement(child) && child.type === BottomSheet.Body
  )

  const footer = childrenArray.filter(
    (child) => React.isValidElement(child) && child.type === BottomSheet.Footer
  )

  const defaultChildren =
    (!body.length && !footer.length) || (footer.length && !body.length) ? (
      <Box py="xs">{children}</Box>
    ) : null

  return (
    <Drawer
      ref={drawerContentRef}
      opened={opened}
      bg="red"
      closeOnClickOutside={closeOnClickOutside}
      closeOnEscape={closeOnEscape}
      onClose={onClose}
      withCloseButton={withCloseButton}
      position="bottom"
      data-sel="bottom-sheet"
      styles={{
        content: {
          overflow: 'hidden',
          borderTopLeftRadius: rem(8),
          borderTopRightRadius: rem(8),
          minHeight: '70%',
        },
        header: {
          display: 'none',
        },
        body: {
          overflow: 'hidden',

          padding: 0,
        },
      }}
    >
      <ScrollArea
        type="hover"
        px="md"
        h={footer.length > 0 ? rem(contentHeight - 80) : rem(contentHeight)}
      >
        {withCloseButton && (
          <Flex justify="center" pb="xs">
            <UnstyledButton
              onClick={onClose}
              mt="md"
              p={0}
              data-sel="bottom-sheet-close"
            >
              <Flex justify="center">
                <IconChevronDown />
              </Flex>
            </UnstyledButton>
          </Flex>
        )}
        {title && <Title order={6}>{title}</Title>}
        {body}
        {defaultChildren}
      </ScrollArea>
      {footer.length > 0 && <>{footer}</>}
    </Drawer>
  )
}

BottomSheet.Body = ({ children }) => <Box py="xs">{children}</Box>

BottomSheet.Footer = ({ children }) => (
  <Group py="xs" px="md" mt="auto" h={rem(80)}>
    {children}
  </Group>
)

export default BottomSheet
