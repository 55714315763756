import { Anchor, rem } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { MenuItem } from 'types/menuItem'

import { Link, useLocation } from '@redwoodjs/router'

import { EXTERNAL_URL_REGEX } from 'src/helpers/url'

import './main-menu.css'

type MainMenuItemsProps = {
  menuItem: MenuItem
}

const MainMenu = ({ menuItem }: MainMenuItemsProps) => {
  const { t } = useTranslation('learning')

  const location = useLocation()
  const isExternalUrl = EXTERNAL_URL_REGEX.test(menuItem.route)

  return (
    <Anchor
      className="main-menu-item"
      component={isExternalUrl ? 'a' : Link}
      to={isExternalUrl ? undefined : menuItem.route}
      href={isExternalUrl ? menuItem.route : undefined}
      target={isExternalUrl ? '_blank' : undefined}
      underline="never"
      ml={rem(20)}
      c={location.pathname === menuItem.route ? 'cnaRed.6' : 'gray.6'}
      styles={{
        root: {
          transition: 'color 0.2s ease',
        },
      }}
    >
      {t(menuItem.label)}
    </Anchor>
  )
}

export default MainMenu
