import { Box, UnstyledButton } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Module } from 'types/graphql'

import BottomSheet from 'src/components/shared/BottomSheet/BottomSheet'

import { LevelSwitcherChildrenProps } from '../../LevelSwitcher'
import LevelSwitcherHeader from '../LevelSwitcherHeader/LevelSwitcherHeader'
import LevelSwitcherMenuItemCard from '../LevelSwitcherMenuItemCard/LevelSwitcherMenuItemCard'

const LevelSwitcherBottomSheet = ({
  levels,
  children,
  currentLevel,
  onClick,
}: LevelSwitcherChildrenProps) => {
  const [opened, { toggle }] = useDisclosure(false)

  const toggleLevel = (level: Module) => {
    toggle()
    onClick(level)
  }

  return (
    <Box>
      <UnstyledButton w="100%" onClick={toggle} data-sel="level-switcher">
        {children}
      </UnstyledButton>
      <BottomSheet opened={opened} onClose={toggle}>
        <Box pb="sm">
          <LevelSwitcherHeader />
        </Box>
        {levels.map((level, index) => {
          const isActive = currentLevel.id === level.id
          return (
            <UnstyledButton
              pb="sm"
              w="100%"
              key={index}
              onClick={() => toggleLevel(level)}
              data-sel="level-item"
            >
              <LevelSwitcherMenuItemCard
                isActive={isActive}
                title={level.title}
              />
            </UnstyledButton>
          )
        })}
      </BottomSheet>
    </Box>
  )
}
export default LevelSwitcherBottomSheet
