export type MediaType = {
  url: string
  type: string
  title?: string
}

const useMediaType = (media: string) => {
  if (!media) return
  return media.split('/')[0]?.toUpperCase()
}

const useMediaArray = (media: MediaType[]) => {
  if (!media?.length) return

  return media.map((item) => {
    const { url, type } = item as MediaType
    return {
      url,
      type: useMediaType(type),
    } as MediaType
  }) as MediaType[]
}

const useFirstMedia = (coverMedia: unknown) => {
  const media = useMediaArray(coverMedia as MediaType[])
  return media && media.length > 0 ? (media[0] as MediaType) : ({} as MediaType)
}

export { useMediaType, useMediaArray, useFirstMedia }
