import { Box, Text, rem } from '@mantine/core'
import { ActivityAnswerField } from 'types/graphql'

import { CorrectAnswerChildrenAnswerProps } from 'src/components/learning/CorrectAnswer/CorrectAnswer'
import Markdown from 'src/components/shared/Markdown/Markdown'

const fillInTheBlanks = (answer: ActivityAnswerField) => {
  const splitedText = answer?.text.split(/_*_/i)

  const answerText = splitedText.map((text, index) => {
    const choiceText = answer?.choices[index]?.text || ''
    return `${text} ${choiceText}`
  })

  return answerText.join(' ')
}

const Answer = ({
  answer,
  count,
}: {
  answer: ActivityAnswerField
  count?: number
}) => {
  const answerFilled = fillInTheBlanks(answer)
  const newLineMarker = answerFilled?.split('\n')

  return (
    <Box mt={rem(16)}>
      {count && (
        <Text size="sm" mb={rem(4)}>
          {`(${count})`}
        </Text>
      )}

      {newLineMarker.map((text: string, index: number) => (
        <Text key={index} mb={rem(8)} size="sm">
          <Markdown content={text} />
          {index !== newLineMarker.length - 1 && <br />}
        </Text>
      ))}
    </Box>
  )
}

const CorrectFillInTheBlanks = ({
  answers,
}: CorrectAnswerChildrenAnswerProps) => {
  return (
    <>
      {answers.map((answer, index) => (
        <Answer
          key={answer.id}
          answer={answer}
          count={answers.length > 1 ? index + 1 : undefined}
        />
      ))}
    </>
  )
}

export default CorrectFillInTheBlanks
