import { Loader, rem } from '@mantine/core'
import { useMediaProvider, isAudioProvider } from '@vidstack/react'

import styles from './AudioMediaLoader.module.css'

type Props = {
  isLoading: boolean
}

const AudioMediaLoader = ({ isLoading }: Props) => {
  const provider = useMediaProvider()

  return isLoading && isAudioProvider(provider) ? (
    <div className={styles.container}>
      <Loader size={rem(24)} />
    </div>
  ) : null
}

export default AudioMediaLoader
