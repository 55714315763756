import { Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'

const NoUnitsFound = () => {
  const { t } = useTranslation('learning')

  return <Title order={6}>{t('main.no-units-found')}.</Title>
}

export default NoUnitsFound
