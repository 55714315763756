import { useState, useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  Grid,
  SegmentedControl,
  Paper,
  Text,
  Title,
  Group,
  Center,
  Loader,
  rem,
} from '@mantine/core'
import { IconHeadphones, IconVideo } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { Resource } from 'types/graphql'

import Levels from 'src/components/learning/Levels/Levels'
import NavBar from 'src/components/learning/NavBar/NavBar'
import TopMainSection from 'src/components/learning/TopMainSection/TopMainSection'
import { ContentMediaType } from 'src/enums/learning/media/ContentMediaType'
import { GET_RESOURCES } from 'src/graphql/learning/learning'
import { useIsSmallScreen } from 'src/hooks/shared/screen'
import { useLevel } from 'src/providers/learning/level/LevelProvider'

import Resources from './components/Resources/Resources'
import styles from './ResourcesPage.module.css'

export type UnitResource = {
  title: string
  id: number
  module: {
    order: number
  }[]
  resources: {
    id: number
    name: string
    order: number
    media: {
      type: string
      url: string
    }[]
  }[]
}

const getResourcesByMediaType = (
  data: Resource[],
  mediaType: string
): UnitResource[] => {
  const moduleResources: Record<string, UnitResource> = data.reduce(
    (
      groupedResources,
      {
        module: { title, id, module },
        id: resourceId,
        media,
        name,
        order,
      }: Resource
    ) => {
      if (Array.isArray(media)) {
        const resources = media
          .filter(
            (item): item is { type: string; url: string } =>
              typeof item === 'object'
          )
          .filter(({ type }) => type?.toUpperCase() === mediaType)
          .map((mediaItem) => ({
            name,
            id: resourceId,
            media: [mediaItem],
            order,
          }))

        if (resources.length) {
          groupedResources[id] = groupedResources[id] || {
            title,
            id,
            module,
            resources: [],
          }
          groupedResources[id].resources.push(...resources)
        }
      }
      return groupedResources
    },
    {}
  )

  return Object.values(moduleResources)
    .sort((a, b) => (a.module?.[0]?.order ?? 0) - (b.module?.[0]?.order ?? 0))
    .map((module) => ({
      ...module,
      resources: module.resources.sort(
        (a, b) => (a.order ?? 0) - (b.order ?? 0)
      ),
    }))
}

const ResourcesPage = () => {
  let audioResources = []
  let videoResources = []

  const [getResources, { data, loading }] = useLazyQuery(GET_RESOURCES)
  const { currentLevel } = useLevel()
  const { t } = useTranslation('learning')
  const [tab, setTab] = useState('audio')
  const isSmallScreen = useIsSmallScreen()

  useEffect(() => {
    const { id } = currentLevel || {}

    if (!id) return

    getResources({
      variables: { parentModuleId: id },
    })
  }, [currentLevel, getResources])

  if (data) {
    audioResources = getResourcesByMediaType(
      data.FilterResources,
      ContentMediaType.AUDIO
    )

    videoResources = getResourcesByMediaType(
      data.FilterResources,
      ContentMediaType.VIDEO
    )
  }

  return (
    <Levels>
      <Grid gutter="lg" data-sel="resources">
        <Grid.Col span={{ md: 4, lg: 3 }} m={0} visibleFrom="md" p={rem(10)}>
          <NavBar />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, md: 8, lg: 9 }}>
          <TopMainSection navigation />
          <SegmentedControl
            className={styles.resourceToggle}
            bg="transparent"
            p="0"
            maw="100%"
            onChange={setTab}
            value={tab}
            withItemsBorders={false}
            styles={{
              label: { padding: 0 },
            }}
            data-sel="resources-toggle"
            data={[
              {
                value: 'audio',
                label: (
                  <Paper
                    w="100%"
                    px={rem(16)}
                    py={rem(8)}
                    radius={rem(8)}
                    bg={tab === 'audio' ? 'gray.1' : 'transparent'}
                    data-sel="audio-tab"
                  >
                    <Group gap={rem(6)} wrap="nowrap">
                      <IconHeadphones
                        size={21}
                        color={
                          tab === 'audio'
                            ? 'var(--mantine-color-cnaRed-6)'
                            : 'var(--mantine-color-dark-3)'
                        }
                      />
                      <Text
                        c={tab === 'audio' ? 'cnaRed.6' : 'dark.3'}
                        style={{
                          textAlign: 'left',
                        }}
                      >
                        {t(isSmallScreen ? 'main.audio' : 'main.audio-files')}
                      </Text>
                    </Group>
                  </Paper>
                ),
              },
              {
                value: 'video',
                label: (
                  <Paper
                    w="100%"
                    px={rem(16)}
                    py={rem(8)}
                    bg={tab === 'video' ? 'gray.1' : 'transparent'}
                    radius={rem(8)}
                    data-sel="video-tab"
                  >
                    <Group gap={rem(6)} wrap="nowrap">
                      <IconVideo
                        size={21}
                        color={
                          tab === 'video'
                            ? 'var(--mantine-color-cnaRed-6)'
                            : 'var(--mantine-color-dark-3)'
                        }
                      />
                      <Text
                        c={tab === 'video' ? 'cnaRed.6' : 'dark.3'}
                        style={{
                          textAlign: 'left',
                        }}
                      >
                        {t(isSmallScreen ? 'main.video' : 'main.video-files')}
                      </Text>
                    </Group>
                  </Paper>
                ),
              },
            ]}
          />

          {loading ? (
            <Center h="100%" w="100%">
              <Loader />
            </Center>
          ) : (
            <>
              <Title pt={rem(24)} order={5}>
                {currentLevel?.title}
              </Title>
              <Resources
                key="resources"
                resources={tab === 'audio' ? audioResources : videoResources}
                tab={tab}
              />
            </>
          )}
        </Grid.Col>
      </Grid>
    </Levels>
  )
}

export default ResourcesPage
