import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import store from 'store2'

import translationsEn from 'src/locales/learning/en/translations.json'
import translationsPtBR from 'src/locales/learning/pt-BR/translations.json'

const i18nextInit = () => {
  const currentLanguage = store('currentLocale') || 'pt_BR'
  store('currentLocale', currentLanguage)
  i18next.use(initReactI18next).init({
    lng: currentLanguage,
    interpolation: { escapeValue: false },
    resources: {
      en: {
        learning: translationsEn,
      },
      pt_BR: {
        learning: translationsPtBR,
      },
    },
  })
}

export default i18nextInit
