import {
  Flex,
  Box,
  Text,
  Badge,
  Divider,
  Group,
  Title,
  Anchor,
  rem,
} from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { ActivityAnswer } from 'types/graphql'

import { Link } from '@redwoodjs/router'

import { formatDate, formatTime } from 'src/helpers/date'
interface ExtendedWritingAnswer extends ActivityAnswer {
  lessons?: Array<{
    lessonTitle: string
    activities?: Array<{
      activityOrder: number
      teacherFeedbackAt: string | null
      unitId: number
      lessonId: number
      activityId: number
      latestUserAnswerAt: string | null
      latestAnswerType: string | null
    }>
  }>
}

type WritingListProps = {
  answers: ExtendedWritingAnswer[]
}

const WritingList = ({ answers }: WritingListProps) => {
  const { t } = useTranslation('learning')

  const badgeProperties = {
    CORRECTED: {
      bg: 'green.1',
      c: 'green.9',
      title: 'main.corrected',
    },
    WAITING: {
      bg: 'gray.2',
      c: 'black',
      title: 'main.to-correct',
    },
  }

  return (
    <Box>
      <Text
        size={rem(14)}
        pb={rem(16)}
        styles={{ root: { lineHeight: rem(18) } }}
      >
        {t('main.no-pending-activities-description')}
      </Text>
      {answers.map((answer, index) => (
        <Box key={index} data-sel="writing-item">
          <Flex align="center" mb={rem(20)}>
            <Divider w="100%" color="gray.4" />
            <Text
              style={{ flexGrow: 1, flexShrink: 0 }}
              size={rem(12)}
              px={rem(8)}
              data-sel="unit-title"
              c="cnaRed.6"
            >
              {answer.unitTitle}
            </Text>
            <Divider w="100%" color="gray.4" />
          </Flex>
          {answer.lessons?.length > 0 &&
            answer.lessons?.map((lesson, index) => (
              <Box key={index} mb={rem(15)}>
                {lesson.activities?.length > 0 &&
                  lesson.activities?.map((activity, index) => {
                    const hasTeacherFeedback = !!activity.teacherFeedbackAt
                    const badgeStatus = !hasTeacherFeedback
                      ? 'WAITING'
                      : 'CORRECTED'
                    return (
                      <Box
                        key={index}
                        mb={rem(25)}
                        data-sel={`writing-${activity.latestAnswerType?.toLocaleLowerCase()}`}
                      >
                        <Anchor
                          component={Link}
                          to={`/learning/unit/${activity.unitId}/lessons/${activity.lessonId}/writing-activity/${activity.activityId}`}
                          data-sel="writing-link"
                          underline="never"
                        >
                          <Group justify="space-between">
                            <Box>
                              <Text size={rem(12)} c="dark.3">
                                {answer.unitTitle}
                              </Text>
                              <Title
                                data-sel="lesson-title"
                                c="black"
                                order={5}
                              >
                                {lesson.lessonTitle} - {t('common.activity')}{' '}
                                {activity.activityOrder + 1}
                              </Title>
                              <Text
                                size={rem(10)}
                                c="dark.3"
                                data-sel="sent-at"
                              >
                                {hasTeacherFeedback
                                  ? t('main.correction-sent-at', {
                                      date: formatDate(
                                        activity.teacherFeedbackAt
                                      ),
                                      time: formatTime(
                                        activity.teacherFeedbackAt
                                      ),
                                    })
                                  : t('main.answer-sent-at', {
                                      date: formatDate(
                                        activity.latestUserAnswerAt
                                      ),
                                      time: formatTime(
                                        activity.latestUserAnswerAt
                                      ),
                                    })}
                              </Text>
                            </Box>
                            <Group align="center">
                              <Badge
                                px="sm"
                                py={rem(14)}
                                {...badgeProperties[badgeStatus]}
                                data-sel={`activity-${badgeStatus.toLowerCase()}`}
                              >
                                <Text
                                  size={rem(12)}
                                  {...badgeProperties[badgeStatus]}
                                >
                                  {t(badgeProperties[badgeStatus].title)}
                                </Text>
                              </Badge>
                              <IconChevronRight size={32} color="black" />
                            </Group>
                          </Group>
                        </Anchor>
                      </Box>
                    )
                  })}
              </Box>
            ))}
        </Box>
      ))}
    </Box>
  )
}

export default WritingList
