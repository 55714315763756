import { Module } from 'types/graphql'

import { setStoreLevel } from 'src/helpers/storage'
import { useIsSmallScreen } from 'src/hooks/shared/screen'
import { useLevel } from 'src/providers/learning/level/LevelProvider'

import LevelSwitcherBottomSheet from './components/LevelSwitcherBottomSheet/LevelSwitcherBottomSheet'
import LevelSwitcherDropdown from './components/LevelSwitcherDropdown/LevelSwitcherDropdown'

type Props = {
  children: React.ReactNode
}

export type LevelSwitcherChildrenProps = {
  onClick: (level: Module) => void
  children: React.ReactNode
  levels: Module[]
  currentLevel: Module
}

const LevelSwitcher = ({ children }: Props) => {
  const { levels, currentLevel, setLevels } = useLevel()
  const isSmallScreen = useIsSmallScreen()

  const changeLevel = (level: Module) => {
    const { id, slug } = level

    if (currentLevel.id === id) return

    const newLevel = levels.find((level) => level.id === id)
    setStoreLevel({ id, slug })
    setLevels({
      levels,
      currentLevel: newLevel,
    })
  }

  return isSmallScreen ? (
    <LevelSwitcherBottomSheet
      onClick={changeLevel}
      levels={levels}
      currentLevel={currentLevel}
    >
      {children}
    </LevelSwitcherBottomSheet>
  ) : (
    <LevelSwitcherDropdown
      levels={levels}
      currentLevel={currentLevel}
      onClick={changeLevel}
    >
      {children}
    </LevelSwitcherDropdown>
  )
}

export default LevelSwitcher
