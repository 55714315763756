import { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import {
  Text,
  Group,
  rem,
  Progress,
  Box,
  ActionIcon,
  Tooltip,
} from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
import { IconUpload, IconX, IconPaperclip } from '@tabler/icons-react'
import { Purpose } from 'types/graphql'

import Media from 'src/components/learning/Media'

const GET_UPLOAD_URL_QUERY = gql`
  query GetUploadUrlQuery($input: GetUploadURLInput!) {
    GetUploadURL(input: $input)
  }
`

// This `uploadFile` uses XMLHttpRequest to upload a file to a given URL
// because we want to track the upload progress. fetch() does not
// support it, unfortunately.
const uploadFile = async (url, file, onProgress): Promise<string> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open('PUT', url, true)
    xhr.setRequestHeader('Content-Type', file.type)

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        onProgress((event.loaded / event.total) * 100)
      }
    }

    xhr.onload = () => {
      if (xhr.status === 200) {
        console.log('File uploaded successfully')

        // Strip url of search params
        const urlWithoutParams = url.split('?')[0]
        console.log('URL without params:', urlWithoutParams)

        resolve(urlWithoutParams)
      } else {
        reject(xhr.responseText)
      }
    }

    xhr.onerror = () => reject(xhr.responseText)

    xhr.send(file)
  })
}

const MediaCard = ({ url, type, onRemove }) => {
  return (
    <Box>
      <Group justify="center" wrap="nowrap">
        <Media py={0} url={url} type={type?.toUpperCase()} zoom />
        <Tooltip label="Remover arquivo">
          <ActionIcon onClick={onRemove}>
            <IconX />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Box>
  )
}

export type MediaType = 'image' | 'video' | 'audio'

const DEFAULT_ACCEPTED_MIME_TYPES = ['image/*', 'video/*', 'audio/*']

type ActivityMediaUploadZoneProps = {
  onDrop: (url: string, mediaType: MediaType) => void
  onRemove?: () => void
  acceptedMimeTypes?: string[]
  text?: string
  w?: string
  media: { url: string; type: MediaType }[]
}

export const ActivityMediaUploadZone = ({
  onDrop,
  onRemove,
  acceptedMimeTypes = DEFAULT_ACCEPTED_MIME_TYPES,
  text = '',
  w = 'auto',
  media = [],
}: ActivityMediaUploadZoneProps) => {
  const [progress, setProgress] = useState<number | null>(null)

  const graphql = useApolloClient()

  const handleDrop = async (files) => {
    const { data } = await graphql.query({
      query: GET_UPLOAD_URL_QUERY,
      variables: {
        input: {
          filename: files[0].name,
          contentType: files[0].type,
          purpose: 'ACTIVITY' as Purpose,
        },
      },
    })

    const url = data.GetUploadURL

    try {
      const urlWithoutParams = await uploadFile(url, files[0], (progress) =>
        setProgress(progress)
      )

      const mediaType = files[0].type.split('/')[0] as MediaType

      onDrop(urlWithoutParams, mediaType)
    } catch (error) {
      console.error(error)
    }
  }

  if (media?.length > 0)
    return (
      <MediaCard
        url={media[0]?.url}
        type={media[0]?.type?.toUpperCase()}
        onRemove={() => {
          setProgress(null)
          onRemove()
        }}
      />
    )

  return (
    <Dropzone
      w={w}
      onDrop={handleDrop}
      accept={acceptedMimeTypes}
      multiple={false}
      radius="lg"
    >
      <Group justify="center">
        <Dropzone.Accept>
          <IconUpload
            style={{
              width: rem(32),
              height: rem(32),
              color: 'var(--mantine-color-blue-6)',
            }}
            stroke={1.5}
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            style={{
              width: rem(32),
              height: rem(32),
              color: 'var(--mantine-color-red-6)',
            }}
            stroke={1.5}
          />
        </Dropzone.Reject>
        {!media?.length && (
          <Dropzone.Idle>
            <IconPaperclip
              style={{
                width: rem(32),
                height: rem(32),
                color: 'var(--mantine-color-dimmed)',
              }}
              stroke={1.5}
            />
          </Dropzone.Idle>
        )}

        {progress && progress < 100 && (
          <div>
            <Progress
              value={progress}
              animated={progress !== 100}
              w={rem(300)}
            />
          </div>
        )}
        {!progress && text && (
          <div>
            <Text size="sm" c="dimmed" inline mt={7}>
              {text}
            </Text>
          </div>
        )}
        {media && media.length > 0 && (
          <Media url={media[0]?.url} type={media[0]?.type?.toUpperCase()} />
        )}
      </Group>
    </Dropzone>
  )
}

export default ActivityMediaUploadZone
