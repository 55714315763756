import { useContext } from 'react'

import { Card, Title, Button, rem } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@redwoodjs/web'

import { notifications } from 'src/components/shared/Notifications'
import { CREATE_ANSWER_ACTIVITY } from 'src/graphql/learning/learning'
import { useIsSmallScreen } from 'src/hooks/shared/screen'
import { ActivityContext } from 'src/providers/learning/activity/ActivityProvider'
import { useActivityResolver } from 'src/providers/learning/activity/ActivityResolverProvider'

import ActivityFeedback from '../ActivityFeedback/ActivityFeedback'
import CorrectAnswer from '../CorrectAnswer/CorrectAnswer'

import styles from './ActivityResult.module.css'

const ActivityResult = () => {
  const isSmallScreen = useIsSmallScreen()
  const { setAnswerFeedback, answer, answerFeedback } = useActivityResolver()
  const { attempts } = answerFeedback
  const { isFeedbackVisible, isCorrectAnswerVisible } = answerFeedback
  const { t } = useTranslation('learning')
  const { activity, setActivities } = useContext(ActivityContext)

  const [createAnswerActivity, { loading }] = useMutation(
    CREATE_ANSWER_ACTIVITY
  )

  const { typology } = activity || {}
  const isWriting = typology === 'WRITING'

  const showError = () => {
    notifications.error({
      title: 'Erro ao salvar resposta',
      message: `Por favor tente novamente.`,
    })
  }

  const submitAnswer = async () => {
    try {
      const input = {
        answerFields: activity?.answerFields.map((answerField, index) => ({
          answerFieldId: answerField.id,
          answers: [
            ...(activity?.typology === 'WRITING'
              ? [
                  {
                    discursive: answer[0],
                  },
                ]
              : []),
            ...(activity?.typology === 'FILL_IN_THE_BLANKS' ||
            activity?.typology === 'SEQUENCING'
              ? answer.map((answerItem: string, index) => ({
                  choiceId: parseInt(answerItem),
                  order: answerField.choices[index].order,
                }))
              : []),
            ...(activity?.typology === 'COMBINATION'
              ? [
                  {
                    choiceId: answer && parseInt((answer as string[])[index]),
                  },
                ]
              : []),
            ...(activity?.typology === 'MULTIPLE_CHOICE'
              ? answer.map((answerItem: string) => ({
                  choiceId: parseInt(answerItem),
                }))
              : []),
          ],
        })),
      }

      const response = await createAnswerActivity({
        variables: {
          input,
        },
      })

      const { data } = response
      const { AnswerActivity } = data

      if (AnswerActivity && AnswerActivity?.feedback) {
        const feedback = {
          isFeedbackVisible: true,
          type: AnswerActivity.feedback.type,
          title_en: AnswerActivity.feedback.title_en,
          title_pt_br: AnswerActivity.feedback.title_pt_br,
          explanation_en: AnswerActivity.feedback.explanation_en,
          explanation_pt_br: AnswerActivity.feedback.explanation_pt_br,
          attempts: attempts + 1,
          language: 'en',
        }

        setAnswerFeedback(feedback)

        setActivities((prev) =>
          prev.map((item) => {
            if (item.id === activity.id) {
              return {
                ...item,
                answerFields: item.answerFields.map((answerField) => {
                  const answers = AnswerActivity?.answers?.filter(
                    (answer) => answer.answerFieldId === answerField.id
                  )

                  return {
                    ...answerField,
                    answers: answers || [],
                  }
                }),
              }
            }
            return item
          })
        )
      } else {
        showError()
      }
    } catch (error) {
      showError()
    }
  }

  const hasAnswer = answer.length > 0

  return (
    <Card
      withBorder
      radius={isSmallScreen ? 0 : rem(6)}
      styles={{
        root: {
          boxShadow: isSmallScreen
            ? `0 ${rem(-4)} ${rem(6)} 0 var(--mantine-color-gray-2)`
            : 'none',
        },
      }}
      data-sel="result"
    >
      {!isFeedbackVisible && (
        <>
          <Title order={5} pb={rem(16)} visibleFrom="md">
            {t('main.result')}
          </Title>
          {isCorrectAnswerVisible && <CorrectAnswer />}
          <Button
            onClick={submitAnswer}
            size="lg"
            fullWidth
            disabled={!hasAnswer}
            c="white"
            bg={hasAnswer ? (isWriting ? 'cnaBlue.6' : 'cnaRed.6') : 'dark.1'}
            radius="lg"
            className={
              isWriting
                ? styles.activitySendTeacherButton
                : styles.activityResultButton
            }
            loading={loading}
            data-sel="submit-answer"
          >
            {isWriting ? t('main.send-to-teacher') : t('main.check-answer')}
          </Button>
        </>
      )}
      <ActivityFeedback />
    </Card>
  )
}

export default ActivityResult
