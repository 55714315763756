import { type DefaultLayoutTranslations } from '@vidstack/react/player/layouts/default'

export const PT_BR: DefaultLayoutTranslations = {
  'Caption Styles': 'Estilos de Legenda',
  'Captions look like this': 'Legendas parecem com isso',
  'Closed-Captions Off': 'Closed-Captions Off',
  'Closed-Captions On': 'Closed-Captions On',
  'Display Background': 'Exibir Fundo',
  'Enter Fullscreen': 'Entrar em Tela Cheia',
  'Enter PiP': 'Entrar em PiP',
  'Exit Fullscreen': 'Sair de Tela Cheia',
  'Exit PiP': 'Sair de PiP',
  'Google Cast': 'Google Cast',
  'Keyboard Animations': 'Animações de Teclado',
  'Seek Backward': 'Buscar para Trás',
  'Seek Forward': 'Buscar para Frente',
  'Skip To Live': 'Pular para Ao Vivo',
  'Text Background': 'Fundo do Texto',
  Accessibility: 'Acessibilidade',
  AirPlay: 'AirPlay',
  Announcements: 'Anúncios',
  Audio: 'Audio',
  Auto: 'Auto',
  Boost: 'Aumentar',
  Captions: 'Legendas',
  Chapters: 'Capítulos',
  Color: 'Cor',
  Connected: 'Conectado',
  Connecting: 'Conectando',
  Continue: 'Continuar',
  Default: 'Padrão',
  Disabled: 'Desabilitado',
  Disconnected: 'Desconectado',
  Download: 'Download',
  Family: 'Família',
  Font: 'Fonte',
  Fullscreen: 'Fullscreen',
  LIVE: 'AO VIVO',
  Loop: 'Loop',
  Mute: 'Mudo',
  Normal: 'Normal',
  Off: 'Off',
  Opacity: 'Opacidade',
  Pause: 'Pause',
  PiP: 'PiP',
  Play: 'Play',
  Playback: 'Playback',
  Quality: 'Qualidade',
  Replay: 'Replay',
  Reset: 'Reset',
  Seek: 'Buscar',
  Settings: 'Configurações',
  Shadow: 'Sombra',
  Size: 'Tamanho',
  Speed: 'Velocidade',
  Text: 'Texto',
  Track: 'Track',
  Unmute: 'Tirar Mudo',
  Volume: 'Volume',
}
