import { Grid, Box, rem } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Metadata } from '@redwoodjs/web'

import CurrentLevel from 'src/components/learning/CurrentLevel/CurrentLevel'
import Levels from 'src/components/learning/Levels/Levels'
import NavBar from 'src/components/learning/NavBar/NavBar'
import ShortcutsNav from 'src/components/learning/ShortcutsNav'
import TopMainSection from 'src/components/learning/TopMainSection/TopMainSection'
import Units from 'src/components/learning/Units/Units'
import { useIsSmallScreen } from 'src/hooks/shared/screen'

const HomePage = () => {
  const { t } = useTranslation('learning')
  const isSmallScreen = useIsSmallScreen()
  return (
    <Levels>
      <Metadata title="Home" description="Home page" />
      <Grid gutter="lg">
        {!isSmallScreen && (
          <Grid.Col span={{ md: 4, lg: 3 }} m={0} p={rem(10)}>
            <NavBar />
          </Grid.Col>
        )}
        <Grid.Col span={{ xs: 12, md: 8, lg: 9 }}>
          <TopMainSection title={t('main.my-learning')} />
          {isSmallScreen && (
            <Box>
              <CurrentLevel />
              <ShortcutsNav />
            </Box>
          )}
          <Units />
        </Grid.Col>
      </Grid>
    </Levels>
  )
}

export default HomePage
