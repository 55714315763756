import { Fragment } from 'react'

import { Box, rem, Paper, Title } from '@mantine/core'

import MediaPlayer from 'src/components/shared/MediaPlayer'

type Props = {
  resource: {
    id: number
    name: string
    media: {
      type: string
      url: string
    }[]
  }
  tab: string
  currentMediaId: number | null
  onMediaPlay?: (id: number) => void
}

const Resource = ({ resource, tab, onMediaPlay, currentMediaId }: Props) => {
  return (
    <Box mb={rem(16)}>
      {resource.media?.map((media, mediaIndex) => (
        <Fragment key={mediaIndex}>
          {tab === 'audio' ? (
            <Box data-sel="audio-resource">
              <MediaPlayer
                url={media.url}
                title={resource.name}
                id={resource.id}
                onMediaPlay={onMediaPlay}
                currentMediaId={currentMediaId}
              />
            </Box>
          ) : (
            <Paper
              withBorder
              radius={rem(16)}
              mb={rem(18)}
              px={rem(16)}
              py={rem(16)}
              bg="white"
              data-sel="video-resource"
            >
              <Title pb={rem(8)} order={5}>
                {resource.name}
              </Title>
              <MediaPlayer
                url={media.url}
                title={resource.name}
                id={resource.id}
                onMediaPlay={onMediaPlay}
                currentMediaId={currentMediaId}
              />
            </Paper>
          )}
        </Fragment>
      ))}
    </Box>
  )
}

export default Resource
