import { useState } from 'react'

import i18next from 'i18next'
import store from 'store2'

import LanguageSelect from './LanguageSelect'

const LanguageSelectI18n = () => {
  const [language, setLanguage] = useState<string>(i18next.language)

  const changeLanguage = (language: string) => {
    i18next.changeLanguage(language)
    store('currentLocale', language)
    setLanguage(language)
  }

  return (
    <LanguageSelect language={language} onLanguageChange={changeLanguage} />
  )
}

export default LanguageSelectI18n
