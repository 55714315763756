import {
  Flex,
  Group,
  Title,
  Image,
  Button,
  LoadingOverlay,
  Box,
  rem,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { routes, navigate } from '@redwoodjs/router'

import { useNextLesson } from 'src/hooks/learning/activity'

const LessonsCompletion = () => {
  const { t } = useTranslation('learning')
  const { currentLesson, goToNextLesson, loading, nextLesson } = useNextLesson()

  const backToUnit = () => {
    navigate(routes.learningUnitLessons())
  }

  return (
    <>
      {loading ? (
        <LoadingOverlay visible overlayProps={{ backgroundOpacity: 1 }} />
      ) : (
        <Flex
          data-sel="congratulations-lesson"
          w="100%"
          h="100%"
          p="md"
          direction="column"
          align="center"
        >
          <Box w={rem(200)} h={rem(200)} mx="auto" data-sel="media">
            <Image src="/learning/lesson-congratulations.png" fit="contain" />
          </Box>
          <Group gap={rem(8)} py="xl">
            <Title order={3} c="cnaRed.6" data-sel="title-highlight">
              {currentLesson?.subModule?.title}
            </Title>
            <Title order={3} data-sel="title">
              {t('main.completed')}!
            </Title>
          </Group>
          <Title order={5} ta="center" data-sel="description">
            {t('main.activity-congratulations')}
          </Title>
          <Box py="xl" w="100%" maw={rem(350)}>
            <Button
              h={rem(50)}
              w="100%"
              onClick={goToNextLesson}
              data-sel="go-to-next"
            >
              {t('main.go-to')} {nextLesson?.subModule?.title}
            </Button>
            <Button
              bg="white"
              c="black"
              h={rem(50)}
              w="100%"
              onClick={backToUnit}
              style={{
                marginTop: 'var(--mantine-spacing-xl)',
                borderColor: 'var(--mantine-color-gray-3)',
              }}
              data-sel="go-to-back"
            >
              {t('main.back-to-unit')}
            </Button>
          </Box>
        </Flex>
      )}
    </>
  )
}

export default LessonsCompletion
