import { useContext } from 'react'

import { UnstyledButton, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { ActivityResolverContext } from 'src/providers/learning/activity/ActivityResolverProvider'

const FeedbackTranslate = () => {
  const { setFeedbackLanguage } = useContext(ActivityResolverContext)
  const { t } = useTranslation('learning')

  return (
    <UnstyledButton
      onClick={setFeedbackLanguage}
      data-sel="feedback-translation"
    >
      <Text td="underline" c="cnaRed.6">
        {t('main.translate')}
      </Text>
    </UnstyledButton>
  )
}

export default FeedbackTranslate
