import { browserTracingIntegration, replayIntegration } from '@sentry/browser'
import * as Sentry from '@sentry/react'

import { MODE, SENTRY_DSN } from 'src/helpers/env'

if (!SENTRY_DSN) {
  console.error(
    `Missing SENTRY_DSN environment variable. Did you forget to add it to
    your redwood.toml file in includeEnvironmentVariables and in your .env?`
  )
  console.info(
    `Copy this into your redwood.toml file, also add the SENTRY_DSN variable to your .env:`
  )
  console.info(`
  includeEnvironmentVariables = [
    "SENTRY_DSN"
  ]

  `)
  console.error('Sentry is disabled for now')
} else {
  const dsn = SENTRY_DSN

  Sentry.init({
    dsn,
    environment: MODE,
    integrations: [
      browserTracingIntegration(),
      replayIntegration({
        maskAllText: true, // prevent sensitive data from being sent to Sentry
        blockAllMedia: true, // prevent media (like profile picture) from being sent to Sentry
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

export default Sentry
