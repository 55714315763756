import { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Title, Grid, rem, Center, Loader } from '@mantine/core'
import { ActivityAnswer } from 'types/graphql'

import { routes } from '@redwoodjs/router'

import Levels from 'src/components/learning/Levels'
import NavBar from 'src/components/learning/NavBar/NavBar'
import TopMainSection from 'src/components/learning/TopMainSection/TopMainSection'
import { GET_WRITING_ACTIVITIES } from 'src/graphql/learning/learning'
import { useIsSmallScreen } from 'src/hooks/shared/screen'
import { useLevel } from 'src/providers/learning/level/LevelProvider'

import WritingList from './components/WritingList/WritingList'
import WritingListPlaceholder from './components/WritingListPlaceholder/WritingListPlaceholder'

type ProcessedLesson = ActivityAnswer & {
  lessonOrder: number
  activities: Array<{
    activityId: string
    activityOrder: number
    latestAnswerType: string
    latestUserAnswerAt: string | null
    teacherFeedbackAt: string | null
  }>
}

const processWritingActivities = (answers: ActivityAnswer[]) => {
  const grouped = answers.reduce((acc, a) => {
    const unit = acc[a.unitId] || (acc[a.unitId] = { ...a, lessons: {} })
    const lesson =
      unit.lessons[a.lessonId] ||
      (unit.lessons[a.lessonId] = {
        ...a,
        activities: [],
        lessonOrder: a.lessonOrder,
      })
    lesson.activities.push({
      activityId: a.activityId,
      activityOrder: a.activityOrder,
      latestAnswerType: a.latestAnswerType,
      teacherFeedbackAt: a.teacherFeedbackAt,
      latestUserAnswerAt: a.latestUserAnswerAt,
      unitId: a.unitId,
      lessonId: a.lessonId,
    })
    return acc
  }, {})

  return Object.values(
    grouped as Record<
      string,
      ActivityAnswer & {
        lessons: Record<string, ProcessedLesson>
      }
    >
  )
    .sort((a, b) => a.unitOrder - b.unitOrder)
    .map((unit) => ({
      ...unit,
      lessons: Object.values(unit.lessons)
        .filter((lesson) =>
          lesson.activities.some(
            (activity) => activity.latestAnswerType !== null
          )
        )
        .sort((a, b) => (a.lessonOrder ?? 0) - (b.lessonOrder ?? 0))
        .map((lesson) => ({
          ...lesson,
          activities: lesson.activities
            .filter((activity) => activity.latestAnswerType !== null)
            .sort((a, b) => (a.activityOrder ?? 0) - (b.activityOrder ?? 0)),
        })),
    }))
}

const WritingActivitiesPage = () => {
  const [getWritingActivities, { data, loading }] = useLazyQuery(
    GET_WRITING_ACTIVITIES
  )
  const { currentLevel } = useLevel()
  const isSmallScreen = useIsSmallScreen()
  let answers = []

  useEffect(() => {
    const { id } = currentLevel

    if (!id) return

    getWritingActivities({
      variables: {
        levelId: id,
      },
    })
  }, [currentLevel, getWritingActivities])

  const { WritingAnswers } = data || {}

  if (WritingAnswers) {
    answers = processWritingActivities(WritingAnswers?.answers)
  }

  const hasAnswers =
    answers.length > 0 && answers?.find((answer) => answer.lessons.length > 0)
  return (
    <Levels>
      <Grid gutter="lg" data-sel="writing-list">
        {!isSmallScreen && (
          <Grid.Col span={{ md: 4, lg: 3 }} m={0} p={rem(10)}>
            <NavBar />
          </Grid.Col>
        )}
        <Grid.Col span={{ xs: 12, md: 8, lg: 9 }}>
          <TopMainSection navigation={routes.learningHomePage()} />
          <Title order={4} pb={rem(20)}>
            Writing
          </Title>
          {loading ? (
            <Center h="calc(100% - 125px)">
              <Loader />
            </Center>
          ) : (
            <>
              {hasAnswers ? (
                <WritingList answers={answers} />
              ) : (
                <WritingListPlaceholder />
              )}
            </>
          )}
        </Grid.Col>
      </Grid>
    </Levels>
  )
}

export default WritingActivitiesPage
