import LessonsCompletion from 'src/components/learning/LessonsCompletion/LessonsCompletion'
import UnitsCompletion from 'src/components/learning/UnitsCompletion/UnitsCompletion'
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage'
import { ActivityProvider } from 'src/providers/learning/activity/ActivityProvider'

type CongratulationsPageProps = {
  step: string
}

const CongratulationsPage = ({ step }: CongratulationsPageProps) => {
  const component = {
    unit: <UnitsCompletion />,
    lesson: (
      <ActivityProvider>
        <LessonsCompletion />
      </ActivityProvider>
    ),
  }
  const hasValidStep = Object.keys(component).includes(step)
  return hasValidStep ? component[step] : <NotFoundPage />
}

export default CongratulationsPage
