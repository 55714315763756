import { useState } from 'react'

import { Image, Modal, MantineRadius, rem } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { useImageIsCached } from 'src/hooks/shared/image'
import { useIsSmallScreen } from 'src/hooks/shared/screen'

type Props = {
  url?: string
  title?: string
  radius?: MantineRadius
  onLoad?: () => void
  zoom?: boolean
}

const ImageMedia = ({ url, title, radius, onLoad, zoom }: Props) => {
  const [opened, { open, close }] = useDisclosure(false)
  const isImageCached = useImageIsCached(url)
  const [loading, setLoading] = useState(true)

  const isSmallScreen = useIsSmallScreen()

  const setOnload = (loading: boolean) => {
    setLoading(loading)
    onLoad && onLoad()
  }

  return (
    <>
      <Image
        fit="contain"
        radius={radius}
        w="100%"
        maw={rem(700)}
        mah={{ xs: rem(210), sm: rem(280) }}
        src={url}
        alt={title}
        opacity={loading && !isImageCached ? 0 : 1}
        onError={() => setOnload(false)}
        onLoad={() => setOnload(false)}
        onClick={() => zoom && open()}
        styles={{
          root: {
            flexBasis: 'auto',
            transition: 'opacity .5s ease-in-out',
            cursor: zoom ? 'pointer' : 'inherit',
          },
        }}
      />
      <Modal size="xl" centered={isSmallScreen} opened={opened} onClose={close}>
        <Image w="100%" h="auto" src={url} alt={title} />
      </Modal>
    </>
  )
}

export default ImageMedia
