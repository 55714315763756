import {
  Flex,
  FlexProps,
  MantineRadius,
  MantineSpacing,
  StyleProp,
} from '@mantine/core'

import ImageMedia from 'src/components/learning/ImageMedia'
import MediaPlayer from 'src/components/shared/MediaPlayer'
import { ContentMediaType } from 'src/enums/learning/media/ContentMediaType'

type Props = {
  type?: string
  url?: string
  title?: string
  radius?: MantineRadius
  onLoad?: () => void
  py?: StyleProp<MantineSpacing>
  zoom?: boolean
  preload?: 'auto' | 'metadata' | 'none'
  load?: 'eager' | 'idle' | 'visible' | 'play' | 'custom'
  posterLoad?: 'eager' | 'idle' | 'visible' | 'custom'
} & FlexProps

const Media = ({
  type,
  url,
  title,
  py = 'xl',
  radius = 0,
  onLoad,
  zoom = false,
  preload,
  load,
  posterLoad,
  ...rest
}: Props) => {
  return (
    <Flex justify="center" py={py} w="100%" h="100%" {...rest} data-sel="media">
      {type === ContentMediaType.IMAGE ? (
        <ImageMedia
          url={url}
          title={title}
          radius={radius}
          onLoad={onLoad}
          zoom={zoom}
        />
      ) : (
        <MediaPlayer
          url={url}
          title={title}
          preload={preload}
          load={load}
          posterLoad={posterLoad}
        />
      )}
    </Flex>
  )
}

export default Media
