import Answer from 'src/components/learning/CorrectAnswer/components/Answer'
import { CorrectAnswerChildrenAnswerProps } from 'src/components/learning/CorrectAnswer/CorrectAnswer'

const CorrectSequencing = ({ answers }: CorrectAnswerChildrenAnswerProps) => (
  <>
    {answers.map((answer, index) => (
      <Answer
        key={answer.id}
        answer={answer}
        count={answers.length > 1 ? index + 1 : undefined}
        showChoicesCount={true}
      />
    ))}
  </>
)

export default CorrectSequencing
