import { Center, Container } from '@mantine/core'

import { useAuth } from 'src/auth'

type RedirectLayoutProps = {
  children?: React.ReactNode
}

const RedirectLayout = ({ children }: RedirectLayoutProps) => {
  const { isAuthenticated, loading } = useAuth()
  if (loading || !isAuthenticated) return <></>

  return (
    <Container h="100vh">
      <Center h="100vh">{children}</Center>
    </Container>
  )
}

export default RedirectLayout
