const calculateProgressPercentage = (progressData: {
  completed: number
  total: number
}) => {
  const { completed, total } = progressData || { completed: 0, total: 0 }

  if (completed === 0 || total === 0) return 0

  const percentage = (completed / total) * 100

  const roundedPercentage = Math.round(percentage * 10) / 10

  if (roundedPercentage > 100) return 100

  return roundedPercentage
}

const sumAllProgress = (
  lessonsProgress: { total: number; completed: number }[]
) =>
  lessonsProgress?.reduce(
    (accumulator, item) => {
      return {
        completed: accumulator.completed + item.completed,
        total: accumulator.total + item.total,
      }
    },
    { completed: 0, total: 0 }
  )

export { calculateProgressPercentage, sumAllProgress }
