import { Router, Route, Set, PrivateSet } from '@redwoodjs/router'

import RedirectLayout from 'src/layouts/RedirectLayout/RedirectLayout'

import { useAuth } from './auth'
import AuthLayout from './layouts/AuthLayout/AuthLayout'
import LearningActivityLayout from './layouts/LearningActivityLayout/LearningActivityLayout'
import LearningLayout from './layouts/LearningLayout/LearningLayout'
import LearningWritingActivityLayout from './layouts/LearningWritingActivityLayout/LearningWritingActivityLayout'
import LearningAdminResourcesListPage from './pages/Learning/Admin/ResourcesListPage/ResourcesListPage'
import LearningCongratulationsPage from './pages/Learning/CongratulationsPage/CongratulationsPage'
import LearningHomePage from './pages/Learning/HomePage/HomePage'
import LearningResourcesPage from './pages/Learning/ResourcesPage/ResourcesPage'
import LearningUnitLessonsPage from './pages/Learning/UnitLessonsPage/UnitLessonsPage'
import WritingActivitiesPage from './pages/Learning/WritingActivitiesPage/WritingActivitiesPage'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'
import HomePage from './pages/Onboarding/HomePage/HomePage'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route notfound page={NotFoundPage} />

      <Set wrap={AuthLayout}>
        <Route path="/login" page={OnboardingLoginPage} name="login" />
        <Route path="/access/forgot" page={OnboardingForgotPasswordPage} name="forgot" />
        <Route path="/access/reset" page={OnboardingResetPasswordPage} name="reset" />
        <Route path="/signup" page={OnboardingSignupPage} name="signUp" />
      </Set>

      <PrivateSet unauthenticated="login">
        <Set wrap={RedirectLayout}>
          <Route path="/" page={HomePage} name="home" />
        </Set>

        <Set wrap={LearningLayout}>
          <Route path="/learning/resources" page={LearningResourcesPage} name="learningResourcesPage" />
          <Route path="/learning/dashboard" page={LearningHomePage} name="learningHomePage" />
          <Route path="/learning/unit/lessons" page={LearningUnitLessonsPage} name="learningUnitLessons" />

          <PrivateSet unauthenticated="home" roles="admin">
            <Route path="/learning/admin" page={LearningAdminHomePage} name="learningAdminHomePage" />
            <Route path="/learning/admin/activities" page={LearningAdminActivitiesListPage} name="learningAdminActivitiesList" />
            <Route path="/learning/admin/activities/{id:Int}" page={LearningAdminEditActivityPage} name="learningAdminEditActivity" />
            <Route path="/learning/admin/levels" page={LearningAdminLevelsListPage} name="learningAdminLevelsList" />
            <Route path="/learning/admin/levels/{levelId:Int}/modules" page={LearningAdminModulesListPage} name="learningAdminModulesList" />
            <Route path="/learning/admin/modules/{moduleId:Int}" page={LearningAdminEditModulePage} name="learningAdminEditModule" />
            <Route path="/learning/admin/activities/create" page={LearningAdminCreateActivityPage} name="learningAdminCreateActivity" />
            <Route path="/learning/admin/resources" page={LearningAdminResourcesListPage} name="learningAdminResourcesList" />
          </PrivateSet>
          <Route path="/learning/activities/writing" page={WritingActivitiesPage} name="writingActivities" />
        </Set>

        <Set wrap={LearningActivityLayout}>
          <Route path="/learning/unit/{unitId:Int}/lessons/{lessonId:Int}" page={LearningActivityPage} name="learningActivityPage" />
        </Set>

        <Set wrap={LearningWritingActivityLayout}>
          <Route path="/learning/unit/{unitId:Int}/lessons/{lessonId:Int}/writing-activity/{activityId:Int}" page={LearningWritingActivityPage} name="learningWritingActivityPage" />
        </Set>

        <Route path="/learning/congratulations/{step}" page={LearningCongratulationsPage} name="learningCongratulations" />
      </PrivateSet>
    </Router>
  )
}

export default Routes
