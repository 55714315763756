import { Card, Box, Flex, Badge, Text, rem } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import LevelTitle from 'src/components/learning/LevelTitle/LevelTitle'
import CircleProgressBar from 'src/components/shared/CircleProgressBar/CircleProgressBar'
import {
  calculateProgressPercentage,
  sumAllProgress,
} from 'src/helpers/progress'
import { useLevel } from 'src/providers/learning/level/LevelProvider'

import LevelSwitcher from '../LevelSwitcher/LevelSwitcher'

import styles from './CurrentLevel.module.css'

const CurrentLevel = () => {
  const { levelUnits, currentLevel, courseProgress } = useLevel()
  const { title } = currentLevel
  const { t } = useTranslation('learning')

  const totalUnits = levelUnits?.length

  const currentCourseProgress = courseProgress.filter(
    (cp) =>
      levelUnits.some((levelUnit) => levelUnit.id === cp.id) &&
      cp.id !== currentLevel.id
  )

  const progress = calculateProgressPercentage(
    sumAllProgress(currentCourseProgress)
  )

  return (
    <Card
      withBorder
      radius="md"
      p={0}
      py="md"
      w="100%"
      data-sel="current-level"
    >
      <Flex justify="space-between" align="flex-start" px="md">
        <Flex align="center">
          <CircleProgressBar size={60} progress={progress} />
          <Box pl={rem(8)} className={styles.croppedTitle}>
            <LevelTitle title={title} />
            <Text size="xs" mt="sm">
              {totalUnits} {t(totalUnits > 1 ? 'common.units' : 'common.unit')}
            </Text>
          </Box>
        </Flex>
        <Badge
          fw={500}
          size={rem(12)}
          p="xs"
          c="green.6"
          bg="green.0"
          w="100%"
          maw={rem(70)}
          className="badge"
          style={{
            flexGrow: 1,
            flexShrink: 0,
          }}
        >
          {t('common.active')}
        </Badge>
      </Flex>
      <LevelSwitcher>
        <Card p={0} mx="md" mt="md" radius={rem(8)}>
          <Flex
            bg="gray.0"
            c="black"
            p="xs"
            px="md"
            justify="space-between"
            align="center"
          >
            <Box>
              <LevelTitle title={title} />
              <Text size="xs">{t('main.change-level')}</Text>
            </Box>
            <Box pl={rem(4)}>
              <IconChevronRight size={24} stroke={1.75} />
            </Box>
          </Flex>
        </Card>
      </LevelSwitcher>
    </Card>
  )
}

export default CurrentLevel
