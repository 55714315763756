import { Activity, ActivityAnswerField } from 'types/graphql'

import { AnswerHitEnum } from 'src/enums/learning/activity/AnswerHitEnum'

const orderActivities = (activities: Activity[], lessonId: number) => {
  return [...activities]?.sort((a, b) => {
    const moduleA = a?.modules?.find((module) => module?.moduleId === lessonId)
    const moduleB = b?.modules?.find((module) => module?.moduleId === lessonId)

    return moduleA?.order - moduleB?.order
  })
}

const orderActivityAnswerFields = (answerFields: ActivityAnswerField[]) => {
  const orderedFields = answerFields
    .map((item) => {
      const choices = item?.choices ? [...item.choices] : []

      return {
        ...item,
        choices: choices.sort((a, b) => (a?.order || 0) - (b?.order || 0)),
        coverMedia:
          item?.coverMedia ||
          item?.choices?.find((choice) => choice.coverMedia)?.coverMedia,
        text: item?.text || item?.choices?.find((choice) => choice.text)?.text,
      }
    })
    .sort((a, b) => (a?.order || 0) - (b?.order || 0))

  return orderedFields
}

const answerFieldsKeepCorrectChoices = (
  answerFields: ActivityAnswerField[] = []
) => {
  return answerFields.map((answer) => {
    const choices = answer?.choices || []
    const correctOrderedChoices = choices.filter((choice) => choice.isCorrect)

    return {
      ...answer,
      choices: correctOrderedChoices,
    }
  })
}

const isActivityPending = (activity: Activity) => {
  return activity?.answerFields?.some((answerField) => {
    return (
      answerField?.answers?.length === 0 ||
      answerField?.answers?.some(
        (answer) =>
          answer?.hit === AnswerHitEnum.MISS && activity.typology !== 'WRITING'
      )
    )
  })
}

const isActivityDone = (activity: Activity) => {
  return activity?.answerFields?.every((answerField) => {
    return (
      answerField?.answers?.length > 0 &&
      answerField?.answers?.every(
        (answer) => answer?.hit === AnswerHitEnum.DONE
      )
    )
  })
}

export {
  answerFieldsKeepCorrectChoices,
  orderActivities,
  orderActivityAnswerFields,
  isActivityPending,
  isActivityDone,
}
