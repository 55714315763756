import {
  notifications as notificationsMantine,
  NotificationData,
} from '@mantine/notifications'

import classNames from './Notifications.module.css'

export const notifications = {
  error: ({ title, message, ...rest }: NotificationData) => {
    return notificationsMantine.show({
      color: 'red',
      title,
      message,
      classNames,
      'data-sel': 'error-notification',
      ...rest,
    })
  },
  success: ({ title, message, ...rest }: NotificationData) => {
    return notificationsMantine.show({
      color: 'green',
      title,
      message,
      classNames,
      'data-sel': 'success-notification',
      ...rest,
    })
  },
}
