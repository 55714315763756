import { Box, Center, Container, rem, Image } from '@mantine/core'

type AuthLayoutProps = {
  children?: React.ReactNode
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <Container maw={rem(446)} px={{ xs: 'lg', sm: 0 }} pt="20vh">
      <Center>
        <Box w={'100%'}>
          <Center mb={rem(36)}>
            <Image src={'/logo-cna.svg'} alt="CNA" />
          </Center>
          {children}
        </Box>
      </Center>
    </Container>
  )
}

export default AuthLayout
