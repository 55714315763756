import { ERROR_STYLES } from 'src/pages/FatalErrorPage/FatalErrorPage'

export default () => (
  <main>
    <style
      dangerouslySetInnerHTML={{
        __html: ERROR_STYLES,
      }}
    />
    <section>
      <h1>404 Page Not Found</h1>
      <p>Click bellow to return to homepage:</p>
      <div className="actions">
        <button onClick={() => (window.location.href = '/learning/dashboard')}>
          Go Back Home
        </button>
      </div>
    </section>
  </main>
)
